import IntlTelInput from "./intlTelInput";
export default (phoneList) => {
  phoneList.forEach((phone) => {
    const phoneField = $(phone);
    
    console.log(phoneField);
    if (phoneField.length) {
      phoneField.each((key, val) => {
        //console.log(insta);
        const phoneInput = $(val)
        const iti = IntlTelInput(val, {
          initialCountry: "sa",
          separateDialCode: true,
          
        });
         
        if (!phoneInput.val()) {
          const data = iti.getSelectedCountryData();
          phoneInput.val();
          $("#hdnCountryCode").val(data.dialCode);
        }

        phoneInput.on("countrychange", () => {
          const data = iti.getSelectedCountryData();
          phoneInput.val();
          $("#hdnCountryCode").val(data.dialCode);
        });
        
      });


    }

    phoneField.on("keyup", (e) => {
      let number = phoneField.val();
      number = number.replace(/[^\d.-]/g, "");
      if (number.length > 15) {
        number = number.slice(0, 15);
      }

      phoneField.val();
    });
  });
};
