import {gsap, TweenMax } from 'gsap';

export default class MousePointer {
    constructor() {
        //this.follower = document.getElementById('jada-cursor');
        if($(".events-section").length || $(".gallery-section").length) {
            this.mouseFollower();
        }

        
       

        this.buttonHover();

        setTimeout(() => {
            this.portfolioCardHover();
            this.ecoCardHover();
            this.empHoverEvents();
        }, 1500)

        setTimeout(() => {
            this.dashboardMenuHover();
        }, 600)
    }

    
    mouseFollower = () => {

        var follower = $(".jada-cursor");

        

        var posX = 0,
            posY = 0;

        var mouseX = 0,
            mouseY = 0;

        TweenMax.to({}, 0.01, {
            repeat: -1,
            onRepeat: function () {
                posX += (mouseX - posX) / 9;
                posY += (mouseY - posY) / 9;


                TweenMax.set(follower, {
                   css: {
                       left: posX - 12,
                       top:  posY - 12
                   } 
                });
                
            }
        })

        $(document).on("mousemove", function (e) {
            mouseX = e.clientX;
            mouseY = e.clientY;
        });

        $(".events-section .swiper-container, .case-study-detail-wrapper .gallery-section .swiper-container").on("mouseenter", function (e) {
            follower.addClass("mouse-active");
        //    console.log("mouse-enter");
        });

        $(".slide-icon").on("mouseenter", function (e) {
            follower.removeClass("mouse-active");
            console.log("mouse-enter");
        })

        $(".slide-icon").on("mouseleave", function (e) {
            follower.addClass("mouse-active");
        //    console.log("mouse-enter");
        })

        $(".events-section .swiper-container, .case-study-detail-wrapper .gallery-section .swiper-container").on("mouseleave", function (e) {
            follower.removeClass("mouse-active");
       //     console.log("mouse-leave");
        })

        // $(".events-section .swiper-container .navigation").on("mouseenter", function (e){
        //   follower.removeClass("mouse-active");
        // })

        // $(".events-section .swiper-container .navigation").on("mouseleave", function (e){
        //     follower.addClass("mouse-active");
        //   })

        $(".events-section .swiper-container").on("mouseenter", function (e) {
            follower.addClass("homepage-events-active");
        //    console.log("mouse-enter");
        });
        $(".events-section .swiper-container").on("mouseleave", function (e) {
            follower.removeClass("homepage-events-active");
         //   console.log("mouse-enter");
        });
    }
    
    
    buttonHover = () => {
        $(".jada-btn.bordered_green").mouseenter(function() {
        //    console.log("jada-btn");
            $(this).removeClass("b-green-effects-reverse");
            $(this).addClass("b-green-effects");
            
        })

        $(".jada-btn.bordered_green").mouseleave(function() {
            $(this).removeClass("b-green-effects");
            $(this).addClass("b-green-effects-reverse");
        })

        //filled green

        $(".jada-btn.filled_green").mouseenter(function() {
        //    console.log("jada-btn");
            $(this).removeClass("f-green-effects-reverse");
            $(this).addClass("f-green-effects");
            
        });

        $(".jada-btn.filled_green").mouseleave(function() {
            $(this).removeClass("f-green-effects");
            $(this).addClass("f-green-effects-reverse");
        });

        //arrowed green
        $(".jada-btn.filled_green-arrowed").mouseenter(function() {
        //    console.log("jada-btn");
            $(this).removeClass("fa-green-effects-reverse");
            $(this).addClass("fa-green-effects");
            
        });

        $(".jada-btn.filled_green-arrowed").mouseleave(function() {
            $(this).removeClass("fa-green-effects");
            $(this).addClass("fa-green-effects-reverse");
        });
    }


    portfolioCardHover = () => {
      
        $(".partner-card a").mouseenter(function() {
           console.log("slide-up");
            $(this).parent().closest('.partner-card').removeClass("slide-down");
            $(this).parent().closest('.partner-card').addClass("slide-up");
            
        })

        $(".partner-card a").mouseleave(function() {
            console.log("slide-down");
            $(this).parent().closest('.partner-card').removeClass("slide-up");
            $(this).parent().closest('.partner-card').addClass("slide-down");
        })
        
        $(".partner-card ").on('click', function() {
            $(".partners .partner-card ").removeClass('active-card')
            $(this).addClass('active-card');
        })
    }

    ecoCardHover = () => {
       
        $(".eco-card").mouseenter(function() {
           console.log("slide-up");
            $(this).removeClass("slide-down");
            $(this).addClass("slide-up");
            
        })

        $(".eco-card").mouseleave(function() {
           console.log("slide-down");
            $(this).removeClass("slide-up");
            $(this).addClass("slide-down");
        })
    }

    empHoverEvents = () => {
       
        var cntWd, sldWd, tb;

        cntWd = $("#emp-timeline").innerWidth();
        tb = $("#emp-thumbs");
        sldWd = tb.outerWidth();
        
        if($('body').hasClass('arabic')) {
        //    console.log("this is arabic")
            $("#emp-timeline").mousemove(function(e) {
                tb.css({left: ((cntWd - sldWd)*(e.pageX / cntWd.toFixed(3))).toFixed(1) + "px"});
                tb.css({right: "auto"});     
            })
        }
        else {
            
            $("#emp-timeline").mousemove(function(e) {
                
                tb.css({left: ((cntWd - sldWd)*(e.pageX / cntWd.toFixed(3))).toFixed(1) + "px"});
                
            })
        }
        


    }


    dashboardMenuHover = () => {
    //    console.log("dahboard hover events");
    //    var boxWidth =85+'px';
    //    console.log(boxWidth);
    //    $(".jada-header .dashboard-menu .d-links, .jada-dashboard__header .dashboard-menu .d-links").css('width', 85+'px');
       
    //    $(".jada-header .dashboard-menu .d-links,  .jada-dashboard__header .dashboard-menu .d-links").hover(function(){
    //        var isHovered = $(this).is(":hover");
    //        var resetWidth =0;
    //        if(isHovered) {
    //         var titleWidth = $(this).children('span').width();
            
    //         $(this).css('width', 'auto');
    //         boxWidth = $(this).width();
    //         // console.log(boxWidth);
    //         setTimeout(()=> {
    //             $(this).css('width', (boxWidth + 40) +'px');
    //            // $(this).children('span').css('width', titleWidth+'px');
               
    //           $(this).find('ul').first().stop().show(300);

    //         }, 100)
    //        }
    //    }, function() {
    //     $(this).find('ul').stop().hide(300);
    //      $(this).css('width', 85+'px');
    //    });
    }
}