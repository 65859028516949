export default class Header {
    constructor({
        header,
        htmlBody
    }) {
        this.header = header;
        this.htmlBody = htmlBody;
        this.main = $("main");
        this.desktopMenu = this.htmlBody.find('.mega-menu');
        this.desktopNav = this.htmlBody.find('.side-bar-hamburger');
        this.desktopNavClose = this.htmlBody.find('.desktop-nav-close');
        this.headerSocialLinks = this.header.find('.social-links');
        this.jadaHeader = $(".jada-header");
        this.jadaHeaderLogo = this.jadaHeader.find('.custom-navbar .logo-wrap .logo-icon');
        this.jadaHeaderLogoGreen = this.jadaHeader.find('.custom-navbar .logo-wrap .logo-green');
        this.ApplyFundingPage = $('#apply_now');
        this.bindEvents();
    }

    bindEvents = () => {
        const $container = this.htmlBody;
        
        this.handleDesktopMenu();
    }
    handleDesktopMenu = () => {
       
        if(this.main.hasClass('funding-wrapper') && this.ApplyFundingPage.length){
            this.jadaHeader.addClass('dark')
        };

        this.desktopNav.on('click', (e) => {
            e.preventDefault();
            this.desktopMenu.addClass('active');
            this.htmlBody.addClass('active');
            this.headerSocialLinks.addClass('mega-menu-active');
            this.jadaHeader.addClass('mega-menu-active');
            $(".jada-dashboard__header").addClass('mega-menu-active');
            $(".side-bar-hamburger").addClass('mega-menu-active')
            this.htmlBody.addClass('menu-active');
        });
        this.desktopNavClose.on('click', () => {
            this.desktopMenu.removeClass('active');
            this.htmlBody.removeClass('active');
            this.headerSocialLinks.removeClass('mega-menu-active');
            this.jadaHeader.removeClass('mega-menu-active');
            $(".jada-dashboard__header").removeClass('mega-menu-active');
            this.htmlBody.removeClass('menu-active');
            $(".side-bar-hamburger").removeClass('mega-menu-active')
        });


    };

}