export default class accessibility {
  constructor() {
    this.bindEvents();
  }

  bindEvents = () => {
    var $affectedElements = $("h1, p, h2, h3, h4, h5, h6");

    $affectedElements.each(function () {
      var $this = $(this);
      $this.data("orig-size", $this.css("font-size"));
    });

    $("#btn-increase").click(function () {
      changeFontSize(1);

      setTimeout(() => {
        globalScroll.update();
       
      }, 600);
    });

    $("#btn-decrease").click(function () {
      changeFontSize(-1);

      setTimeout(() => {
        globalScroll.update();
        
      }, 600);
    });

    function changeFontSize(direction) {
      $affectedElements.each(function () {
        var $this = $(this);
        $this.css("font-size", parseInt($this.css("font-size")) + direction);
      });
    }
  };
}
