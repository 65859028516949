

export default class CookiePopup{
    constructor(){
        this.storageType = localStorage;
        this.consentPropertyName = 'jada_consent'; 
    }

    ShouldShowPopup = () => !this.storageType.getItem(this.consentPropertyName);
    
    SaveToStorage = () =>{
        this.storageType.setItem(this.consentPropertyName, true);
    }
}