import { gsap, TweenMax, TimelineMax, Power3, Power2, Circ } from "gsap";
import { ScrollTrigger } from "../../libs/gsap/ScrollTrigger.min";
import "../../libs/splitlines/jquery.splitlines";
gsap.registerPlugin(ScrollTrigger);

var applyAll = false;
export default class customAnimations {
  constructor() {
    
    // applyAll = applyAllBoolean;
    this.bindEvents();
    this.window = $(window);
    this.container = $("body");
    this.siteLoader = $(".site-loader");
    this.textSlider_main = $(".banner-slider");
    this.header = $("header.header");
    this.aboutSectionHomePage = $(".about-section");
    this.aboutSectionImages = $(".about-section .image-gallery");
    this.statsSection = $(".stats-section");
    this.eventsSection = $(".events-section");
    this.socialSection = $(".social-section");
    this.getInTouch = $(".get-in-touch");
    //about blocks
    this.jadaBanner = $(".jada-main-banner");
    this.ourStory = $(".our-story");
    this.allAboutJada = $(".all-about-jada");
    this.videoBlock = $(".video-block");
    this.jadaValues = $(".jada-values-section .row");
    this.jadaValuesContent = $(".jada-values-section .content-box");
    this.$teamSectionList = $(".our-leaders .team-grid .team-member");
    this.$ourMandate = $(".Our-mandate");
    this.economicSection = $(".economic-impact");
    this.economicSectionContent = $(".economic-impact .background-container");
    this.jadaMagazineBlock = $(".jada-magazine-block");

    this.detailBanner = $(".detail-page-banner");
    this.flag = 0;
  }

  bindEvents = () => {
    // console.log('Custom Animation playing', applyBindEvents);
    if (true) {
      let fadeUpElements = $(
        ".partners-slider .swiper-container, .stats-section .content-box span, .stats-section .content-box p, .events-section .tabs-content .list,  .tab-section .nav-item, .mission-vision-block, .our-story span, .our-story .p-40, .range-slider .swiper-container, .all-about-jada .title-block .p, .all-about-jada .video-block, .jada-values-section .content p, .our-leaders .team-tab-section .custom-tabs, .our-leaders .team-featured p, .Our-mandate .content-container p, .Our-mandate .content-container .bottom-title p, .jada-magazine-block p , .company-portfolio-slider, .ventures-video-slider, .venture-social-slider, .emp-detail-wrapper .description-section .container-fluid,.emp-detail-wrapper .biography-section .container-fluid, .emp-detail-wrapper .course-section .container-fluid,  .training-section .title , .training-section .accordion-desc,  .publications-and-resources-wrapper .description-section .container, .partner-card, .listing-section .three-col-grid > div, .two-col-grid > div, .all-emps-wrapper .three-col-grid > div, .case-study-detail-wrapper .description-section .container, .full-banner-img .container > div, .enquiry-now-info-wrap, .headquarters-info-wrap > *, .faqs-wrapper .container-fluid, .privacy-policy .contentcontainer .content > *, .errorcontent .content, .registerd > * , .listing-section .three-col-grid > div, .two-col-grid > div, .all-emps-wrapper .three-col-grid > div, .faqs-wrapper .container-fluid,  .events-listing .list .calender-card "
      );
      fadeUpElements.each((index, item) => {
        $(item).addClass("fade-up");
      });

      let fadeUpOpacityElements = $(".about-section .p_anim");
      fadeUpOpacityElements.each((index, item) => {
        $(item).addClass("fade-up-opacity");
      });

      let titleAnimElements = $(
        ".events-section h2, .partners-slider h2, .social-section .title h2, .get-in-touch p, .our-story h3, .all-about-jada .title-block h2, .jada-values-section h2, .jada-values-section .content h4, .our-leaders h1, .our-leaders .team-featured h2, .Our-mandate .content-container h2, .Our-mandate .content-container .bottom-title h4, .economic-impact h2, .economic-impact h4, .jada-magazine-block h4,  .event-detail-wrapper .description-section .desc ,.privacypolicycon h1"
      );
      titleAnimElements.each((index, item) => {
        $(item).addClass("title-anim");
      });

      let BtnElements = $(
        ".stats-section .jada-btn, .events-section .jada-btn, .get-in-touch .jada-btn, .range-slider .thumb, .jada-magazine-block .jada-btn"
      );
      BtnElements.each((index, item) => {
        $(item).addClass("fade-in-btn");
      });

      let largeImgElements = $(
        ".events-section .swiper-container .bg-events, .our-leaders .tabs-content .bg-img, .Our-mandate .bg-img"
      );
      largeImgElements.each((index, item) => {
        $(item).addClass("fade-img");
      });

      setTimeout(() => {
        this.siteLoader.hide();

        // let splitLinesText = $(
        //   ".jada-banner .swiper-slide h1, .jada-main-banner h1, .detail-page-banner h1"
        // );
        // splitLinesText.each((index, element) => {
        //   $(element).splitLines({
        //     tag: '<div class="heading_line"><span class="split-line">',
        //     width: 100 + "%",
        //     keepHtml: true,
        //   });
        // });

        // if (!$("body").hasClass("arabic")) {
        //   $(".about-section h2").wrap("<div class='heading_line'> </div>");
        //   $(".events-section .events-title-section h2").wrap(
        //     "<div class='heading_line'> </div>"
        //   );
        //   $(".social-section .title h2").wrap(
        //     "<div class='heading_line'> </div>"
        //   );
        //   $(".get-in-touch p").wrap("<div class='heading_line'> </div>");
        //   $(".our-story h3").wrap("<div class='heading_line'> </div>");
        //   $(".our-leaders h1").wrap("<div class='heading_line'> </div>");
        //   $(".all-about-jada h2").wrap("<div class='heading_line'> </div>");
        //   $(".economic-impact h2").wrap("<div class='heading_line'> </div>");
        //   // $(".economic-impact h4").wrap("<div class='heading_line'> </div>");
        //   $(".jada-magazine-block h2").wrap(
        //     "<div class='heading_line'> </div>"
        //   );
        // }
        // //home-banner
        // if (this.textSlider_main.length) {
        //   $(".banner-slider .swiper-slide h1").css("opacity", "1");
        // }

        // //large banner
        // if (this.jadaBanner.length) {
        //   $(".jada-main-banner h1").css("opacity", "1");
        // }

        // //detail-page banner
        // if (this.detailBanner.length) {
        //   $(".detail-page-banner h1").css("opacity", "1");
        // }

        // if (this.jadaBanner.length) {
        //   console.log('banner animation fix');
        //   if (!this.jadaBanner.hasClass("animated2")) {
        //     console.log('banner animation fix');
        //     this.jadaBanner.addClass("animated2");
        //     const t2 = new TimelineMax();
        //     t2.staggerFromTo(
        //       ".jada-main-banner .breadcrumb",
        //       0.3,
        //       { autoAlpha: 0 },
        //       { autoAlpha: 1, ease: Power2.easeOut },
        //       0.1
        //     );
        //     t2.staggerFromTo(
        //       ".jada-main-banner .heading_line .split-line",
        //       0.8,
        //       { autoAlpha: 0, y: 80 },
        //       { autoAlpha: 1, y: -10, ease: Power2.easeOut }
        //     );
        //   }
        // }

        // //detail page banner animation
        // if (this.detailBanner.length) {
        //   if (!this.detailBanner.hasClass("animated2")) {
        //     this.detailBanner.addClass("animated2");
        //     const t2 = new TimelineMax();
        //     t2.staggerFromTo(
        //       ".detail-page-banner .breadcrumb",
        //       0.3,
        //       { autoAlpha: 0 },
        //       { autoAlpha: 1, ease: Power2.easeOut },
        //       0.1
        //     );
        //     t2.staggerFromTo(
        //       ".detail-page-banner .heading_line .split-line",
        //       0.8,
        //       { autoAlpha: 0, y: 80 },
        //       { autoAlpha: 1, y: -10, ease: Power2.easeOut }
        //     );
        //   }
        // }

        //Gsap scrollTrigger
        // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
        globalScroll.on("scroll", ScrollTrigger.update);

        // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
        ScrollTrigger.scrollerProxy(".smooth-scroll", {
          scrollTop(value) {
            return arguments.length
              ? globalScroll.scrollTo(value, 0, 0)
              : globalScroll.scroll.instance.scroll.y;
          }, // we don't have to define a scrollLeft because we're only scrolling vertically.
          getBoundingClientRect() {
            return {
              top: 0,
              left: 0,
              width: window.innerWidth,
              height: window.innerHeight,
            };
          },
          pinType: document.querySelector(".smooth-scroll").style.transform
            ? "transform"
            : "fixed",
        });

        // const parallax = document.querySelectorAll(".parallax-bg");
        // parallax.forEach((elem) => {
        //   gsap.to(elem, {
        //     scrollTrigger: {
        //       trigger: elem,
        //       scrub: true,
        //       scroller: ".smooth-scroll",
        //     },
        //     y: (i, target) =>
        //       -ScrollTrigger.maxScroll(this.window) * target.dataset.speed,
        //     ease: "Power3.easeOut",
        //   });
        // });

        // if($(".section-h1").length) {
        //   $(".section-h1").each((index, item) => {
        //     let el = $(item)[0];
        //     const t1 = gsap.timeline({
        //       ScrollTrigger: {
        //         trigger: el,
        //         scroller: ".scroll-section",
        //       }
        //     });
        //   t1.set(el, {
        //     autoAlpha:0,
        //     y: 100
        //   })
        //     .fromTo(el,
        //              1,
        //               {autoAlpha: 0, y: 50},
        //               {autoAlpha: 1, y:0, ease: "circ.easOut"}
        //               )
        //   })
        // }

        if ($(".title-anim").length) {
          $(".title-anim").each((index, item) => {
            let el = $(item)[0];
            const t1 = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                scroller: ".smooth-scroll",
                // start: "top 95%",
              },
            });

            t1.set(el, {
              autoAlpha: 0,
              y: 80,
            }).fromTo(
              el,
              1,
              { autoAlpha: 0, y: 80 },
              { autoAlpha: 1, y: 0, ease: Power3.easeOut }
            );
          });
        }

        if ($(".fade-up").length) {
          $(".fade-up").each((index, item) => {
            let el = $(item)[0];
            const t1 = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                scroller: ".smooth-scroll",
                // start: "top 90%",
              },
            });
            t1.set(el, {
              autoAlpha: 0,
              y: 0,
            }).staggerFromTo(
              el,
              1,
              { autoAlpha: 0, y: 60 },
              { y: 0, autoAlpha: 1, ease: Power3.easeOut }
            );
          });
        }

        if ($(".fade-up-opacity").length) {
          $(".fade-up-opacity").each((index, item) => {
            let el = $(item)[0];
            const t1 = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                scroller: ".smooth-scroll",
                // start: "top 90%",
              },
            });
            t1.set(el, {
              autoAlpha: 0,
              y: 0,
            }).fromTo(
              el,
              2,
              { autoAlpha: 0, y: 50 },
              { y: 0, autoAlpha: 0.7, ease: Power3.easeOut }
            );
          });
        }

        if ($(".fade-in-btn").length) {
          $(".fade-in-btn").each((index, item) => {
            let el = $(item)[0];
            const t1 = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                scroller: ".smooth-scroll",
                // start: "top 90%",
              },
            });
            t1.staggerFromTo(
              el,
              0.3,
              {
                autoAlpha: 0,
              },
              {
                autoAlpha: 1,
                ease: Power3.easeOut,
              }
            );
          });
        }

        if ($(".fade-img").length) {
          $(".fade-img").each((index, item) => {
            let el = $(item)[0];
            const t1 = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                scroller: ".smooth-scroll",
                // start: "top 90%",
              },
            });
            t1.staggerFromTo(
              el,
              1.7,
              {
                width: "0px",
                autoAlpha: 0,
              },
              {
                width: "100%",
                autoAlpha: 1,
                ease: Power3.easeOut,
              }
            );
          });
        }
        let progressFlag = 0;
        globalScroll.on("scroll", () => {
          if ($(".progress-bars.is-inview").length) {
            if (!progressFlag) {
              //circular-progress-bars
              $(".circle_percent").each(function () {
                var $this = $(this),
                  $dataV = $this.data("percent"),
                  $dataDeg = $dataV * 3.6,
                  $round = $this.find(".round_per");
                $round.css(
                  "transform",
                  "rotate(" + parseInt($dataDeg + 180) + "deg)"
                );
                $this.append(
                  '<div class="circle_inbox"><span class="percent_text"></span></div>'
                );
                $this.prop("Counter", 0).animate(
                  { Counter: $dataV },
                  {
                    duration: 2000,
                    easing: "swing",
                    step: function (now) {
                      $this.find(".percent_text").text(Math.ceil(now) + "%");
                    },
                  }
                );
                if ($dataV >= 51) {
                  $round.css("transform", "rotate(" + 360 + "deg)");
                  setTimeout(function () {
                    $this.addClass("percent_more");
                  }, 1000);
                  setTimeout(function () {
                    $round.css(
                      "transform",
                      "rotate(" + parseInt($dataDeg + 180) + "deg)"
                    );
                  }, 1000);
                }
              });
              progressFlag++;
            }
          }
        });

        ScrollTrigger.addEventListener("refresh", () => globalScroll.update());

        ScrollTrigger.refresh();
      }, 900);
    } 
    
    
    // else {

    //   //for emps 
    //   let fadeUpElements = $(
    //     ".listing-section .three-col-grid > div, .two-col-grid > div, .all-emps-wrapper .three-col-grid > div, .faqs-wrapper .container-fluid,  .events-listing .list .calender-card"
    //   );
    //   fadeUpElements.each((index, item) => {
    //     $(item).addClass("fade-up");
    //   });

    //   //for images
    //   let largeImgElements = $(
    //     ".events-section .swiper-container .bg-events, .our-leaders .tabs-content .bg-img, .Our-mandate .bg-img"
    //   );
    //   largeImgElements.each((index, item) => {
    //     $(item).addClass("fade-img");
    //   });

    //   setTimeout(() => {
    //     this.siteLoader.hide();

    //     //detail page banner animation

    //     //Gsap scrollTrigger
    //     // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    //     globalScroll.on("scroll", ScrollTrigger.update);

    //     // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
    //     ScrollTrigger.scrollerProxy(".smooth-scroll", {
    //       scrollTop(value) {
    //         return arguments.length
    //           ? globalScroll.scrollTo(value, 0, 0)
    //           : globalScroll.scroll.instance.scroll.y;
    //       }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    //       getBoundingClientRect() {
    //         return {
    //           top: 0,
    //           left: 0,
    //           width: window.innerWidth,
    //           height: window.innerHeight,
    //         };
    //       },
    //       pinType: document.querySelector(".smooth-scroll").style.transform
    //         ? "transform"
    //         : "fixed",
    //     });

    //     if ($(".fade-up").length) {
    //       $(".fade-up").each((index, item) => {
    //         let el = $(item)[0];
    //         const t1 = gsap.timeline({
    //           scrollTrigger: {
    //             trigger: el,
    //             scroller: ".smooth-scroll",
    //             start: "top 80%",
    //           },
    //         });
    //         t1.set(el, {
    //           autoAlpha: 0,
    //           y: 0,
    //         }).staggerFromTo(
    //           el,
    //           1,
    //           { autoAlpha: 0, y: 60 },
    //           { y: 0, autoAlpha: 1, ease: Power3.easeOut }
    //         );
    //       });
    //     }

    //     if ($(".fade-img").length) {
    //       $(".fade-img").each((index, item) => {
    //         let el = $(item)[0];
    //         const t1 = gsap.timeline({
    //           scrollTrigger: {
    //             trigger: el,
    //             scroller: ".smooth-scroll",
    //             start: "top 70%",
    //           },
    //         });
    //         t1.staggerFromTo(
    //           el,
    //           1.7,
    //           {
    //             width: "0px",
    //             autoAlpha: 0,
    //           },
    //           {
    //             width: "100%",
    //             autoAlpha: 1,
    //             ease: Power3.easeOut,
    //           }
    //         );
    //       });
    //     }

    //     ScrollTrigger.addEventListener("refresh", () => globalScroll.update());

    //     ScrollTrigger.refresh();
    //   }, 1000);
    // }
  };
}
