export const updatePanelHelper = () => { 
    $('#triggerClick button').click();
    var optionElements = $('.stage-of-fund-selector option');
    var innerOptionsElements = $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li');
    optionElements.each((index , ele ) => {
        var dataAttribute = $(ele).data('fundslx'); 
        if (dataAttribute !== undefined) {
            innerOptionsElements.eq(index).attr('data-fundslx', dataAttribute);
        }
    });
    $('#triggerClick button').click();
    $('.first-elem-trigger').focus();    
    $('select.type-of-fund-selector').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
       const selectedDataAttribute= $('.company-select.type-of-fund .type-of-fund-selector .dropdown-menu ul li').eq(clickedIndex).data("fundslx");
       setTimeout(()=>{

        $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li[data-fundslx="' + selectedDataAttribute + '"]').eq(0).find('a').eq(0).trigger('click');
        },100)
        if ($(this).find(':selected').data('nolink') === "True") {
            $('.company-select.stage-fund .stage-of-fund-selector').addClass('disabled');
            $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li').hide();
            $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li[data-fundslx="default"]').show();
            $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li[data-fundslx="default"]').eq(0).find('a').eq(0).trigger('click');
           }
           else{
            $('.company-select.stage-fund .stage-of-fund-selector').removeClass('disabled');

        $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li').hide();
        $('.company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li[data-fundslx="'+ selectedDataAttribute +'"]').show();
           }
    });
    $('.company-select.type-of-fund').one('click', () => {
        $('.company-select.type-of-fund .type-of-fund-selector .dropdown-menu ul li ').each(function(index) {
            $(this).attr('data-fundslx', index + 1);
        });    
    });
};