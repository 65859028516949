import "@babel/polyfill";
import "../libs/splitlines/jquery.splitlines";
import { gsap, TweenMax, TimelineMax, Power3, Power2, Circ } from "gsap";
import GLightbox from "glightbox";
import lozad from "lozad";
import "bootstrap";
import "bootstrap-select";
import countrySelect from "../libs/country-picker-flags/countrySelect.min.js";
import IntlTelInput from "../libs/intl-tel-input";
import Header from "./components/Header";
import CookiePopup from "./components/CookiePopup";
import Sliders from "./components/Sliders";
import customAnimations from "./components/customAnimation";
import lottieAnimations from "./components/Lottie";
import accessibility from "./components/Accessibility";
import MousePointer from "./components/hoverEvents";
import locomotive from "./components/locomotive-scroll";
// import ImageLazyLoad from "./components/ImageLazyLoad";
import Tabs from "./components/Tabs";
import { Timeline } from "gsap/gsap-core.js";
import { getSelectedOptionsHtml, updatePanelHelper, updateSelectedOptions } from "./utils";
import Forms from "./components/Forms";

export default new (class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;

    $(async () => {
      if ($(".editmode").length) {
        $(".jada-header").css({ position: "relative" });
      }

      window.GLightbox = GLightbox;

      this.domReady();
    });

    this.window.on("beforeunload", () => {
      $(".init-overlay").fadeIn(1000);
    });
    this.window.on("DOMContentLoaded", () => {
      $(".init-overlay").fadeOut(1000);
    });

    window.onload = () => {
      let cookiePopup = new CookiePopup();
      if (cookiePopup.ShouldShowPopup()) {
        let cookieAccept = $("#cookie--accept");
        let cookiePopup_container = $("#cookie-popup");

        setTimeout(() => {
          cookiePopup_container.removeClass("hidden");
        }, 2000);
        const saveStorage = () => {
          cookiePopup.SaveToStorage();
          cookiePopup_container.addClass("hidden");
          //    console.log("session saved ");
        };
        cookieAccept.on("click", saveStorage);
      }
    };
  }

  domReady = async () => {
    this.handleUserAgent();
    this.initComponents();
    this.bindEvents();
    this.windowResize();
  };

  initComponents = () => {
    if (this.slider.length) {
      new Sliders();
    }

    // if (
    //   (this.main.hasClass("main-homepage") ||
    //     this.main.hasClass("jada-inner-pages")) &&
    //   !this.html.hasClass("editmode")
    // ) {
    // $(".loadAnimation").on("click", function () {
    //   new customAnimations(false);
    //   console.log("custom animations playing on click");
    //   new MousePointer();
    // });
    // setTimeout(()=> {
    //   new customAnimations(true);
    //   console.log("custom animations playing");
    // }, 2000);

    // }

    if (
      (this.main.hasClass("portfolio-wrapper") ||
        this.main.hasClass("publication-news-wrapper") ||
        this.main.hasClass("events-wrapper") ||
        this.main.hasClass("about-wrapper") ||
        this.main.hasClass("emp-listing-wrapper") ||
        this.main.hasClass("emp-detail-wrapper") ||
        this.main.hasClass("funds-detail-wrapper") ||
        this.main.hasClass("serach-results") ||
        this.main.hasClass("careers-wrapper") ||
        this.main.hasClass("case-study-detail-wrapper")) &&
      !this.html.hasClass("editmode")
    ) {
      $(".loadAnimation").on("click", function () {
        // setTimeout(() => {
        new customAnimations();
        // },1000);

        console.log("custom animations playing on click");
        setTimeout(()=> {
          new MousePointer();
        }, 600)
        
        
      });
    } else {
      if (
        (!this.registerPage.length || !this.dashboardPage.length) &&
        !this.html.hasClass("editmode")
      ) {
        setTimeout(() => {
          new customAnimations();
          console.log("custom animations playing");
        }, 500);
      }
    }

    // setTimeout(() => {
      // new customAnimations();
    //   console.log("custom animations playing");
    // }, 1000);

    // Tabs
    if ($(".dashboard-tabs").length) {
      new Tabs();
    }

    //  new genericAnimation();
    new MousePointer();
    new lottieAnimations();

    new accessibility();
    new Forms();
    new Header({
      header: this.header,
      htmlBody: this.htmlBody,
    });

    window.windowScroll = this.windowScroll;
    window.windowResize = this.windowResize;

    const memberPopup = GLightbox({
      selector: ".member-glightbox",
      skin: "team-popup",
      moreLength: 0,
    });

    const executivePopup = GLightbox({
      selector: ".executive-glightbox",
      skin: "executive-popup",
      moreLength: 0,
    });

    const speakerPopup = GLightbox({
      selector: ".speaker-glightbox",
      skin: "speaker-popup",
      moreLength: 0,
    });

    const imagePopup = GLightbox({
      selector: ".image-glightbox",
      skin: "image-popup",
      moreLength: 0,
    });

    const videoPopup = GLightbox({
      selector: ".video-glightbox",
      skin: "video-popup",
      moreLength: 0,
    });

    const testimonialPopupImage = GLightbox({
      selector: ".testimonial-img-glightbox",
      skin: "testimonial-image-popup",
      moreLength: 0,
    });

    const testimonialPopupVideo = GLightbox({
      selector: ".testimonial-video-glightbox",
      skin: "testimonial-video-popup",
      moreLength: 0,
    });

    const emailChangePopup = GLightbox({
      selector: ".email-glightbox",
      skin: "email-popup",
      moreLength: 0,
    });

    const currentLocation = location.href;
    const menuItem = document.querySelectorAll(".mega-menu .page-links");
    const meuItemLength = menuItem.length;
    for (let i = 0; i < meuItemLength; i++) {
      if (menuItem[i].href === currentLocation) {
        menuItem[i].classList.add("active");
      }
    }

    if (this.dashboardPage.length) {
      jQuery.fn.ForceNumericOnly = function () {
        return this.each(function () {
          $(this).keydown(function (e) {
            var key = e.charCode || e.keyCode || 0;
            // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
            // home, end, period, and numpad decimal
            return (
              key == 8 ||
              key == 9 ||
              key == 13 ||
              key == 46 ||
              key == 110 ||
              key == 190 ||
              (key >= 35 && key <= 40) ||
              (key >= 48 && key <= 57) ||
              (key >= 96 && key <= 105)
            );
          });
        });
      };

      $(".otp-boxes input[type=text]").ForceNumericOnly();
      $(".otp-boxes input[type=text]").keyup(function (e) {
        if ($(this).val() > 0) {
          if (
            event.key == 1 ||
            event.key == 2 ||
            event.key == 3 ||
            event.key == 4 ||
            event.key == 5 ||
            event.key == 6 ||
            event.key == 7 ||
            event.key == 8 ||
            event.key == 9 ||
            event.key == 0
          ) {
            $(this).next().focus();
          }
        } else {
          if (event.key == "Backspace") {
            $(this).prev().focus();
          }
        }
      });
    }

    // setTimeout(() => {
      //banner animations
      let splitLinesText = $(
        ".jada-banner .swiper-slide h1, .jada-main-banner h1, .detail-page-banner h1"
      );
      splitLinesText.each((index, element) => {
        console.log(element, "ele");
        $(element).splitLines({
          tag: '<span class="split-lines">',
          width: 100 + '%',
          keepHtml: true,
        });
      });

      if (!$("body").hasClass("arabic")) {
        $(".about-section h2").wrap("<div class='heading_line'> </div>");
        $(".events-section .events-title-section h2").wrap(
          "<div class='heading_line'> </div>"
        );
        $(".social-section .title h2").wrap(
          "<div class='heading_line'> </div>"
        );
        $(".get-in-touch p").wrap("<div class='heading_line'> </div>");
        $(".our-story h3").wrap("<div class='heading_line'> </div>");
        $(".our-leaders h1").wrap("<div class='heading_line'> </div>");
        $(".all-about-jada h2").wrap("<div class='heading_line'> </div>");
        $(".economic-impact h2").wrap("<div class='heading_line'> </div>");
        // $(".economic-impact h4").wrap("<div class='heading_line'> </div>");
        $(".jada-magazine-block h2").wrap("<div class='heading_line'> </div>");
      }
      //home-banner
      if (this.textSlider_main.length) {
        $(".banner-slider .swiper-slide h1").css("opacity", "1");
        // $(".banner-slider .swiper-slide h1 .heading_line").wrap('<span class="split-lines">');
        $(".banner-slider .swiper-slide h1  .split-lines").wrap('<div class="heading_line">');
      }

      //large banner
      if (this.jadaBanner.length) {
        $(".jada-main-banner h1").css("opacity", "1");
        $(".jada-main-banner h1  .split-lines").wrap('<div class="heading_line">');
      }

      //detail-page banner
      if (this.detailBanner.length) {
        $(".detail-page-banner h1").css("opacity", "1");
        $(".detail-page-banner h1 .split-lines").wrap('<div class="heading_line">');
      }

      if (this.jadaBanner.length) {
        console.log("banner animation fix");
        if (!this.jadaBanner.hasClass("animated2")) {
          console.log("banner animation fix");
          this.jadaBanner.addClass("animated2");

          const t2 = new TimelineMax();

          t2.staggerFromTo(
            ".jada-main-banner .breadcrumb",
            0.3,
            { autoAlpha: 0 },
            { autoAlpha: 1, ease: Power2.easeOut },
            0.1
          );
          t2.staggerFromTo(
            ".jada-main-banner .heading_line .split-lines",
            1,
            { autoAlpha: 0, y: 80 },
            { autoAlpha: 1, y: -10, ease: Power2.easeOut }
          );
          let t3 = new TimelineMax();
          t3.staggerFromTo(
            ".jada-main-banner .three-col-block",
            1,
            { autoAlpha: 0 },
            { autoAlpha: 1, ease: Power2.easeOut },
            1
          );
        }
      }

      //detail page banner animation
      if (this.detailBanner.length) {
        if (!this.detailBanner.hasClass("animated2")) {
          this.detailBanner.addClass("animated2");
          const t2 = new TimelineMax();
          t2.staggerFromTo(
            ".detail-page-banner .breadcrumb",
            0.3,
            { autoAlpha: 0 },
            { autoAlpha: 1, ease: Power2.easeOut },
            0.1
          );
          t2.staggerFromTo(
            ".detail-page-banner .heading_line .split-lines",
            0.8,
            { autoAlpha: 0, y: 80 },
            { autoAlpha: 1, y: -10, ease: Power2.easeOut }
          );
        }
      }
    // }, 600);

    //bootstrap-select
    if ($(".selectpicker").length) {
      $(".selectpicker").selectpicker();
    }

    $(document).ready(function () {
      console.log("doc ready")
      if ($(".selectpicker2").length) {
        $(".selectpicker2").selectpicker();
      }
      updatePanelHelper();

      
      if ($(".multitag-select").length) {
      $('.multitag-select').selectpicker();
      }
        
      function updateSelectedTags() {
        var selectedOptions = $('.multitag-select option:selected');
        var selectedText = selectedOptions.map(function () {
            return $(this).text();
        }).get();
        
        var spanTags = selectedText.map(function (item) {
          return '<span data-name="' + item + '" class="selected-tag">' +
               item +
               '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                   '<path d="M9 1L1 9" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
                   '<path d="M1 1L9 9" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
               '</svg>' +
           '</span>';
        });

        $('.multitag-select .filter-option-inner-inner').html(spanTags);
    }

    $('.multitag-select').on('changed.bs.select', function () {
        updateSelectedTags();
    });
    $('.filter-option-inner-inner').on('click', '.selected-tag', function () {
      const selectName = $(this).data('name');
      $('.multitag-select ul.dropdown-menu li').each(function(){
        if($(this).find('.text').text() == selectName){
          $(this).find('.dropdown-item').trigger('click');
        }
      })
    });
  });

    // initialize internal page js here
    if (document.cookie.indexOf("visited=") === -1) {
      setCookie("visited", "1");
      this.htmlBody.find(".logo-middle").fadeIn(500);
    }
    this.siteLoader.delay(1500).fadeOut(500);

    if (this.customTabs.length) {
      $(".custom-tabs li a").on("click", function (e) {
        e.preventDefault();
        $(".custom-tabs li a").removeClass("active");
        $(this).addClass("active");
        let tagid = $(this).data("tab");
        //  console.log(tagid);
        $(".list").removeClass("active").addClass("hide");
        $("#" + tagid)
          .addClass("active")
          .removeClass("hide");
        setTimeout(() => {
          globalScroll.update();
        }, 600);
      });
    }

    if (this.customTabs.length) {
      $(".custom-tabs li a").on("click", function (e) {
        e.preventDefault();
        $(".custom-tabs li a").removeClass("active");
        $(this).addClass("active");
        let tagid = $(this).data("tab");
        //   console.log(tagid);
        $(".list").removeClass("active").addClass("hide");
        $("#" + tagid)
          .addClass("active")
          .removeClass("hide");
        setTimeout(() => {
          globalScroll.update();
        }, 600);
      });
    }

    if (this.dasboardTabs) {
      $(".dashboard-navigation__links li").on("click", function (e) {
        e.preventDefault();
        $(".dashboard-navigation__links li").removeClass("active");
        $(this).addClass("active");
        let tagid = $(this).data("tab");
        //console.log(tagid);
        $(".list").removeClass("active").addClass("hide");
        $("#" + tagid)
          .addClass("active")
          .removeClass("hide");

        $("#emp-detail_wrapper").hide();
        $("#my-emp-detail_wrapper").hide();
      });
    }

    if (this.main.hasClass("login-wrapper")) {
      setTimeout(() => {
        $(".login-box .submit-btn").on("click", function () {
          let msgEle = $();
          let errorMsg = $(
            "#ctl00_PlaceHolderMain_signInControl_FailureText"
          ).text();
          console.log(errorMsg);
          if (errorMsg.length > 0) {
            $(".msgErrorBox p").text(errorMsg);
          }
        });
      }, 400);
    }

    $(".dashboard").on("click", function (e) {
      e.preventDefault();
      //     console.log("mobile dashboard clicked");
      if ($("#my-emp-detail_wrapper").css("display") == "none") {
        $("#training-listing__wrapper").css("display", "block");
        //    console.log("detail hidden");
      }
    });

    $(".trainings").on("click", function (e) {
      e.preventDefault();
      if ($("#emp-detail_wrapper").css("display") == "none") {
        $("#emp-listing__wrapper").css("display", "block");
        //   console.log("detail hidden");
      }
    });

    //scroll to section anchors
    const jadaTimeline = document.querySelector("#timeline");
    $(".scroll-down").on("click", function () {
      globalScroll.scrollTo(jadaTimeline);

      //  console.log("scrolled");
    });

    const meaningOfJada = document.querySelector("#meaning-of-jada");

    $(".meaning").on("click", function () {
      globalScroll.scrollTo(meaningOfJada);

      //     console.log("scrolled");
    });

    const jadaValues = document.querySelector("#jada-values");
    $(".values").on("click", function () {
      globalScroll.scrollTo(jadaValues);
    });

    const jadamagazine = document.querySelector("#jada-magazine");
    $(".year-in-review").on("click", function () {
      globalScroll.scrollTo(jadamagazine);
    });

    if ($(".about-wrapper").length) {
      const ecoblock = document.querySelector("#economic-block");

      if (window.location.href.indexOf("?eco=") > 0) {
        globalScroll.scrollTo(ecoblock);
      }
    }

    if ($(".privacy-policy").length) {
      //   console.log("privacy page");
      const tb1 = document.querySelector(".tab-1");

      $("#tab-1").on("click", function () {
        globalScroll.scrollTo(tb1);

        //    console.log("privacy-logs");
      });

      const tb2 = document.querySelector(".tab-2");
      $("#tab-2").on("click", function () {
        globalScroll.scrollTo(tb2);
      });

      const tb3 = document.querySelector(".tab-3");
      $("#tab-3").on("click", function () {
        globalScroll.scrollTo(tb3);
      });

      const tb4 = document.querySelector(".tab-4");
      $("#tab-4").on("click", function () {
        globalScroll.scrollTo(tb4);
      });

      const tb5 = document.querySelector(".tab-5");
      $("#tab-5").on("click", function () {
        globalScroll.scrollTo(tb5);
      });

      const tb6 = document.querySelector(".tab-6");
      $("#tab-6").on("click", function () {
        globalScroll.scrollTo(tb6);
      });

      const tb7 = document.querySelector(".tab-7");
      $("#tab-7").on("click", function () {
        globalScroll.scrollTo(tb7);
      });

      const tb8 = document.querySelector(".tab-8");
      $("#tab-8").on("click", function () {
        globalScroll.scrollTo(tb8);
      });

      const tb9 = document.querySelector(".tab-9");
      $("#tab-9").on("click", function () {
        globalScroll.scrollTo(tb9);

        //   console.log("scroll to tab-9");
      });

      const tb10 = document.querySelector(".tab-10");
      $("#tab-10").on("click", function () {
        globalScroll.scrollTo(tb10);
      });
    }

    //event-list-calender
    $(".event-list__link").on("click", function (e) {
      e.preventDefault();
      $(".list-view__filters").addClass("el-active");
      $(".calender-view__filters").removeClass("cl-active");
    });

    $(".event-calender__link").on("click", function (e) {
      e.preventDefault();
      $(".calender-view__filters").addClass("cl-active");
      $(".list-view__filters").removeClass("el-active");
    });

    //filter Tabs
    if (this.homePage.length || this.innerPages.length) {
      if (this.filterTabs.length) {
        $(".filter-tabs li").on("click", function (e) {
          e.preventDefault();
          $(".filter-tabs li ").removeClass("active");
          $(this).addClass("active");
          let tagid = $(this).data("tab");
          //  console.log(tagid);
          $(".list").removeClass("active").addClass("hide");
          $("#" + tagid)
            .addClass("active")
            .removeClass("hide");
          setTimeout(() => {
            globalScroll.update();
          }, 600);
        });
      }
    }

    //dasboard tabs
    if ($("#dashboard-filters").length) {
      $("#dashboard-filters li a").on("click", function (e) {
        e.preventDefault();
        $("#dashboard-filters li a").removeClass("active");
        $(this).addClass("active");
        let tagid = $(this).data("tab");
        //    console.log(tagid);
        $(".list").removeClass("active").addClass("hide");
        $("#" + tagid)
          .addClass("active")
          .removeClass("hide");
      });
    }

    if ($("#user-dashboard").length) {
      $("#user-dashboard .filter-tabs li").on("click", function (e) {
        e.preventDefault();
        $(".filter-tabs li ").removeClass("active");
        $(this).addClass("active");
        let tagid = $(this).data("tab");
        //      console.log(tagid);
        $(".list-inner").removeClass("active").addClass("hide");
        $("#" + tagid)
          .addClass("active")
          .removeClass("hide");
      });
    }

    //dasboard footer push up

    //search pop-up
    $(".inner-search").click(function (event) {
      event.preventDefault();
      $(this).addClass("enable-search");
      $(".search_pop-up").addClass("enable-pop-up");
      $("body").addClass("body-fixed");
    });

    $(".search_pop-up .searchTerm").click(function () {
      $(".search_pop-up .searchButton").addClass("active");
    });

    $(".search_pop-up .cancel-search").click(function () {
      $(".search_pop-up .searchButton").removeClass("active");
    });

    $(".main-search").click(function (event) {
      event.preventDefault();
      $(".search_pop-up-white").addClass("enable-pop-up");
      $("body").addClass("body-fixed");

      $(".jada-header").addClass("dark");
      $(".jada-header").removeClass("mega-menu-active");
      $(".side-bar-hamburger").addClass("search-active");
      $(".side-bar-hamburger").removeClass("mega-menu-active");
      $(".mega-menu").removeClass("active");
    });

    $(".search_pop-up-white .cancel-search").click(function (event) {
      if (!$("main").hasClass("header--dark")) {
        $(".jada-header").removeClass("dark");
        //     console.log("rmove dark");
      }
      $(".search_pop-up-white").removeClass("enable-pop-up");
      $(".side-bar-hamburger").removeClass("search-active");
    });

    $(".cancel-search").click(function (event) {
      event.preventDefault();
      $(".jada-main-banner .content h1").removeClass("slideup");
      $(".inner-search").removeClass("enable-search");
      $(".search_pop-up").removeClass("enable-pop-up");

      $("body").removeClass("body-fixed");
    });

    //available seats bar
    $(".horizontal-progress-bar .meter > span").each(function () {
      var totalSeats = $(this).attr("data-total-seats");
      var availableSeats = $(this).attr("data-available-seats");
      var seatPercent = (availableSeats / totalSeats) * 100;
      //    console.log(totalSeats, availableSeats, seatPercent);
      $(this).css({ width: seatPercent + "%" });
    });

    //accordion section
    $(".open_acc").on("click", function (j) {
      //     console.log("accordion clicked");
      var dropDown = $(this)
        .closest(".time-topic-wrap")
        .find(".drop-down-info");
      $(this)
        .closest(".accordion-tab")
        .find(".drop-down-info")
        .not(dropDown)
        .slideUp();

      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this)
          .closest(".accordion-tab")
          .find(".open_acc.active")
          .removeClass("active");
        $(this).addClass("active");
      }

      dropDown.stop(false, true).slideToggle();
      j.preventDefault();
      setTimeout(() => {
        globalScroll.update();
        //      console.log("scroll updated");
      }, 600);
    });

    //emp detail section
    $(".course-section .title ").on("click", function () {
      $(".course-section").toggleClass("act");
      $(this).next().slideToggle(300);
      setTimeout(() => {
        globalScroll.update();
        //     console.log("scroll updated");
      }, 600);
    });

    $(".biography-section .title ").on("click", function () {
      $(".biography-section").toggleClass("act");
      $(this).next().slideToggle(400);
      setTimeout(() => {
        globalScroll.update();
        //      console.log("scroll updated");
      }, 600);
    });

    //questionnaire pop-up
    $(".open-questionnaire").on("click", function () {
      $(".questionnaire").addClass("active");
      $("body").addClass("body-fixed");
    });

    $(".q-close").on("click", function () {
      $(".questionnaire").removeClass("active");
      $("body").removeClass("body-fixed");
    });

    $(".back-to-q1").bind("click", function () {
      //    console.log("back to q1");
      $(".professional").removeClass("active");
      $(".interested").removeClass("active");
      $(".q1-container").removeClass("hide");
    });

    //sucessfully submitted survey

    //survey pop up
    $(".open-survey").on("click", function () {
      $(".survey").addClass("active");
      $("body").addClass("body-fixed");
    });

    $(".s-close").on("click", function () {
      $(".survey").removeClass("active");
      $("body").removeClass("body-fixed");
    });

    //trainer section
    $(".trainer-header").on("click", function (j) {
      //    console.log("trainer clicked");
      var dropDownn = $(this)
        .closest(".trainer-desc-wrap")
        .find(".trainer-dropdown");
      $(this)
        .closest(".trainer-desc-wrap")
        .find(".trainer-dropdown")
        .not(dropDownn)
        .slideUp();

      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this)
          .closest(".trainer-desc-wrap")
          .find(".trainer-header.active")
          .removeClass("active");
        $(this).addClass("active");
      }

      dropDownn.stop(false, true).slideToggle();
      j.preventDefault();
      setTimeout(() => {
        globalScroll.update();
        //     console.log("scroll updated");
      }, 600);
    });

    if ($(".jada-values-section").length) {
      $(".tab-content-box").on("click", function () {
        // $(".tab-content-box").removeClass("active");
        // $(this).addClass("active");
        var p_height = $(this).children("p").outerHeight();
        var h_height = $(this).children("h4").height();
        var boxHeight = p_height + h_height;
        //    console.log("para height :", p_height);
        //    console.log("heading height :", h_height);
        $(".tab-content-box h4").css({ opacity: "0.2" });
        $(".tab-content-box").removeClass("active");
        $(".tab-content-box").height(h_height);
        $(this).height(boxHeight);
        $(this).addClass("active");

        setTimeout(() => {
          globalScroll.update();
          //     console.log("scroll updated");
        }, 600);
      });
    }

    // eco block parallax
    if (this.window.width() > 1090) {
      if ($(".eco-block-background-container").length) {
        // var $this = this;
        setTimeout(() => {
          var ecoCardHeight = $(".eco-cards-section").height();

          $(".eco-block-background-container").height(ecoCardHeight);
        }, 200);
      }
    }

    //page template for inner pages on jada
    if (
      this.main.hasClass("jada-inner-pages") ||
      this.main.hasClass("dashboard-page")
    ) {
      // this.header.addClass("inner-page-header");
      // this.sideMenu.addClass("inner-page-menu");
    }

    //home page template
    if (this.main.hasClass("main-homepage")) {
      this.header.addClass("homepage-page-header");
      this.sideMenu.addClass("homepage-page-menu");
    }

    //page template for detail pages
    if (this.main.hasClass("header--dark")) {
      this.header.addClass("dark");
      //    console.log("dark-header22");
    }

    $(".speak").on("click", function (e) {
      e.preventDefault();
      $("#whistle-blowing").addClass("in-view");
      $("body").addClass("body-fixed");
      //    console.log("open whistle");
    });

    // $("#speak-up-footer").on("click", function (e) {
    //   e.preventDefault();
    //   $("#whistle-blowing").addClass("in-view");
    //   $("body").addClass("body-fixed");
    //   console.log("open whistle");
    // });

    $(".p-close").on("click", function () {
      $("#whistle-blowing").removeClass("in-view");
      $("body").removeClass("body-fixed");
    });

    $("#apply").on("click", function () {
      $("#apply_now").addClass("in-view");
      $("body").addClass("body-fixed");
    });
    $(".a-close").on("click", function () {
      $("#apply_now").removeClass("in-view");
      $("body").removeClass("body-fixed");
    });

    //login popups
    //emails
    $("#change-email-btn").on("click", function () {
      $("#change-email-popup").addClass("in-view");
      $("body").addClass("body-fixed");
    });
    $("#change-email-popup .a-close").on("click", function () {
      $("#change-email-popup").removeClass("in-view");
      $("body").removeClass("body-fixed");
    });

    //delete account
    $("#delete-acc-btn").on("click", function () {
      $("#delete-acc").addClass("in-view");
      $("body").addClass("body-fixed");
    });
    $("#delete-acc .d-close").on("click", function () {
      $("#delete-acc").removeClass("in-view");
      $("body").removeClass("body-fixed");
    });
    $("#delete-acc .white").on("click", function () {
      $("#delete-acc").removeClass("in-view");
      $("body").removeClass("body-fixed");
    });

    //passwords
    $("#change-phone-btn").on("click", function () {
      $("#change-phone-popup").addClass("in-view");
      $("body").addClass("body-fixed");
    });
    $("#change-phone-popup .a-close, #change-phone-popup .cancel").on(
      "click",
      function () {
        $("#change-phone-popup").removeClass("in-view");
        $("body").removeClass("body-fixed");
      }
    );

    //phone get otp
    $("#change-phone-popup .phonebox.wrapper .getotp").on("click", () => {
      $("#change-phone-popup .phonebox.wrapper").hide();
      $("#change-phone-popup .optbox.wrapper").show();
    });

    //edit my profile
    $("#edit-profile-btn").on("click", () => {
      $("#my-profile-details").hide();
      $("#edit-profile_wrapper").show();
    });

    $("#my-profile-btn").on("click", () => {
      $("#edit-profile_wrapper").hide();
      $("#my-profile-details").show();
    });

    $("#change-phone-popup  .btp").on("click", () => {
      //  console.log("back to phone")
      $("#change-phone-popup .phonebox.wrapper").show();
      $("#change-phone-popup .optbox.wrapper").hide();
    });

    document.querySelectorAll("#emp-detail-open").forEach((item) => {
      item.addEventListener("click", (event) => {
        //    console.log("opened emp detail");
        $("#training-listing__wrapper").hide();
        $("#my-emp-detail_wrapper").show();
      });
    });

    $("#btt").on("click", () => {
      $("#training-listing__wrapper").show();
      $("#my-emp-detail_wrapper").hide();
    });

    //all emps
    document.querySelectorAll("#open-all-emp-detail").forEach((item) => {
      item.addEventListener("click", (event) => {
        //   console.log("opened all emp detail");
        $("#emp-listing__wrapper").hide();
        $("#emp-detail_wrapper").show();
      });
    });

    $("#bta").on("click", () => {
      $("#emp-listing__wrapper").show();
      $("#emp-detail_wrapper").hide();
    });

    $(".input").each(function () {
      if ($(this).val() != "") {
        $(this).closest(".abs_label").addClass("green");
      }
    });

    $("#imageUpload").change(function (data) {
      var imageFile = data.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.onload = function (evt) {
        $("#imagePreview").attr("src", evt.target.result);
        $("#imagePreview").hide();
        $("#imagePreview").fadeIn(650);
      };
    });

    $("#imageDelete").click(function () {
      //    console.log("delete-img");
      $("#imagePreview").attr("src", "dist/images/dashboard/profile-img.png");
      $("#imagePreview").hide();
      $("#imagePreview").fadeIn(650);
    });

    $(".close-pop-up").click(function () {
      $(".success-message").hide(300);
    });

    //mobile menu dropdown
    $(".dashboard-menu.mobile .accessibility .access").click(function () {
      //    console.log("mobile access toggled");
      $(".dashboard-menu.mobile .accessibility ul").slideToggle();
    });

    //title tabs

    //login & register header
    if (this.main.hasClass("login-regsiter_wrapper")) {
      this.header.addClass("login-header");
      this.sideMenu.addClass("login-header");
    }

    //dasboard-header
    if (this.main.hasClass("dashboard-page")) {
      this.header.addClass("dashboard-header");
      this.sideMenu.addClass("dashboard-header");
    }

    //scroll down

    //lozad
    setTimeout(() => {
      const observer = lozad(".lozad", {
        rootMargin: "0px", //syntax similar that of CSS Margin
        threshold: 0.1, // ratio of element convergence
      });

      observer.observe();
    }, 1000);
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
      observer.observe();
    });

    if (
      this.dashboardPage.length ||
      this.registerPage.length ||
      this.contactPage.length ||
      this.applyFundingPage.length
    ) {
      if ($(".phone").length) {
        // console.log("intl init");
        this.intlInput();
        $(".phone").addClass("intl-init");
      }
    }

    if (!$(".phone").hasClass("intl-init")) {
      $(".speak").on("click", () => {
        this.intlInput();
        $(".phone").addClass("intl-init");
        // console.log("intl init");
      });

      $("#apply").on("click", () => {
        this.intlInput();
        $(".phone").addClass("intl-init");
        // console.log("intl init");
      });
    }

    this.move();

    $("#faqs-accordion").on(
      "hidden.bs.collapse  shown.bs.collapse",
      function () {
        setTimeout(function () {
          globalScroll.update();
        }, 200);
      }
    );

    $(function () {
      $(".panel:first-child").addClass("show");
    });

    $(".panel .panel-heading a").on("click", () => {
      $(".panel").removeClass("show");
    });

    if ($(".smooth-scroll").length) {
      new locomotive({
        window: this.window,
        header: this.header,
        htmlBody: this.htmlBody,
        sideMenu: this.sideMenu,
      });
    }

    if (this.registerPage.length || this.dashboardPage.length) {
      $(".country-origin").countrySelect({
        defaultCountry: "sa",
        responsiveDropdown: true,
        defaultStyling: "outside",
      });

      $(".country-res").countrySelect({
        defaultCountry: "sa",
        Dropdown: true,
        defaultStyling: "outside",
      });

      setTimeout(() => {
        let default_country =
          $(".country-new").value ??
          "Saudi Arabia (‫المملكة العربية السعودية‬‎)";
        $(".country-new").countrySelect({
          setCountry: default_country,
          defaultCountry: "sa",
          responsiveDropdown: true,
          defaultStyling: "outside",
        });
      }, 600);

      setTimeout(() => {
        let default_origin_country =
          $(".country2").value ?? "Saudi Arabia (‫المملكة العربية السعودية‬‎)";
        $(".country2").countrySelect({
          setCountry: default_origin_country,
          defaultCountry: "sa",
          responsiveDropdown: true,
          defaultStyling: "outside",
        });
      }, 600);
    }

    // setTimeout(()=> {
    //   new ImageLazyLoad();
    //   console.log('lazy load running');
    // }, 5000);
  };

  intlInput = () => {
    IntlTelInput([".txtPhoneNumber"]);
  };

  move = (e) => {
    //   console.log(e);
  };

  setDomMap = () => {
    this.window = $(window);
    this.htmlNbody = $("body, html");
    this.html = $("html");
    this.htmlBody = $("body");
    this.main = $("main");
    this.siteLoader = $(".site-loader");
    this.header = $(".jada-header");
    this.footer = $("footer");
    this.wrapper = $("#Jada-main-wrapper");
    this.pushDiv = this.wrapper.find(".push");
    this.slider = $(".swiper-container");
    this.sideMenu = $(".side-bar-hamburger");
    this.customTabs = $(".custom-tabs");
    this.dasboardTabs = $(".dashboard-navigation__links");
    this.filterTabs = $(".filter-tabs");
    this.scrollSection = $(".scroll-section");
    this.homePage = $(".main-homepage");
    this.innerPages = $(".jada-inner-pages");
    this.mapContainer = $("#map_canvas");
    this.registerPage = $(".login-regsiter_wrapper");
    this.applyFundingPage = $("#apply_now.remove-transform");
    this.dashboardPage = $(".dashboard-page");
    this.contactPage = $(".contact-us-wrapper");
    this.textSlider_main = $(".banner-slider");
    this.jadaBanner = $(".jada-main-banner");
    this.detailBanner = $(".detail-page-banner");
  };

  bindEvents = () => {
    // window Events
    this.window.resize(this.windowResize).scroll(this.windowScroll);
    function togglePopup() {
      $(".content").toggle();
    }
  };
  

  windowResize = () => {
    this.screenWidth = this.window.width();
    this.screenHeight = this.window.height();
  };

  windowScroll = () => {
    const topOffset = this.window.scrollTop();
    //   console.log("windowScroll");

    // console.log(topOffset, "window scroll works");
    // this.fixedPos();
  };

  handleUserAgent = () => {
    // detect mobile platform
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      this.mobileDevice = true;
      this.htmlBody.addClass("ios-device");
      $("#follower").css({ display: "none" });
    }
    if (navigator.userAgent.match(/Android/i)) {
      this.mobileDevice = true;
      this.htmlBody.addClass("android-device");
      $("#follower").css({ display: "none" });
    }

    // detect desktop platform
    if (navigator.appVersion.indexOf("Win") !== -1) {
      this.htmlBody.addClass("win-os");
    }
    if (navigator.appVersion.indexOf("Mac") !== -1) {
      this.htmlBody.addClass("mac-os");
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf("MSIE") > 0 ||
      navigator.appVersion.indexOf("Trident/") > 0
    ) {
      this.htmlBody.addClass("ie11");
    }
    //detect ie 9

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.html.addClass("ieEdge");
    }

    // Specifically for IE8 (for replacing svg with png images)
    if (this.html.hasClass("ie8")) {
      const imgPath = "/themes/theedge/images/";
      $("header .logo a img,.loading-screen img").attr(
        "src",
        `${imgPath}logo.png`
      );
    }

    // show ie overlay popup for incompatible browser
    if (this.html.hasClass("ie9")) {
      const message = $(
        '<div class="no-support"> You are using outdated browser. Please <a href="https://browsehappy.com/" target="_blank">update</a> your browser or <a href="https://browsehappy.com/" target="_blank">install</a> modern browser like Google Chrome or Firefox.<div>'
      );
      this.htmlBody.prepend(message);
    }
  };
})();

