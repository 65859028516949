import { gsap, TimelineMax, Power2, Power3 } from "gsap";
import Swiper from "swiper/bundle";
import lozad from "lozad";
import rangeslide from "../../../libs/rangeSlide/rangeslide.min";

export default class Sliders {
  constructor() {
    this.window = $(window);
    this.navSlider = $(".mega-menu .info-slider .swiper-container");
    this.homeBanner = $(".banner-slider .swiper-container");
    this.homeUpcomingEventsSlider = $(
      ".tabs-content #upcoming-events .swiper-container"
    );
    this.homePartnerSlider = $(".partners-slider .swiper-container");
    this.homePastEventsSlider = $(
      ".tabs-content #past-events .swiper-container"
    );
    this.homepageSocial = $(".social-gallery .swiper-container");
    this.threeColSlider = $(".three-col-block .swiper-container");
    this.missionVisionBlock = $(".about-tabs .mission-vision-block");
    this.rangeSlider = $(".range-slider");
    this.ventureVideoSlider = $(".ventures-video-slider");
    this.companyPortfolioSlider = $(".company-portfolio-slider");
    this.ventureSocialSlider = $(".venture-social-slider");
    this.threeColEmp = $(".three-col-block-emp");
    this.caseStudySlider = $(".case-study-detail-wrapper .swiper-container");
    this.careerImagesSlider = $(".swiper-container.images");
    this.bindEvents();
  }

  bindEvents = () => {
    if (this.homeBanner.length) {
      this.homeBannerSliderInit();
    }

    if(this.navSlider.length) {
      this.navsSliderInit();
    }

    if (this.homePartnerSlider.length) {
      this.homePartnerSliderInit();
    }

    if (
      this.homeUpcomingEventsSlider.length ||
      this.homePastEventsSlider.length
    ) {
      this.homeEventsSliderInit();
    }

    if (this.homepageSocial.length) {
      this.homeSocialSlider();
    }
     
    // if(this.window.width() <= 1090 ) {
      if (this.threeColSlider.length) {
        this.threeColSliderInit();
      }
    // }
   
    if (this.missionVisionBlock.length) {
      this.missionVisionSlider();
    }

    setTimeout(() => {
      if (this.rangeSlider.length) {
        this.rangeSliderInit();
      }
    }, 600);

    if (this.caseStudySlider.length) {
      this.caseStudySliderInit();
    }

    if (this.ventureVideoSlider.length) {
      this.VentureVideoSliderInit();
    }

    if (this.companyPortfolioSlider.length) {
      this.portfolioSliderInit();
    }

    if (this.ventureSocialSlider.length) {
      this.VentureSocialSliderInit();
    }

    if (this.threeColEmp.length) {
      this.threeColEmpSliderInit();
    }

    if(this.careerImagesSlider.length){
      this.careerImagesSliderInit();
    }
  };

  navsSliderInit = () => {
    new Swiper(".mega-menu .info-slider .swiper-container", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 20,
      autoplay: {
        delay: 7000,
      },
      grabCursor: true,
      on: {
        init: () => {
          this.navSlider.find(".slide-timer").addClass("active");
        },
        slideChangeTransitionStart: () => {
          this.navSlider.find(".slide-timer").removeClass("active");
        },
        slideChangeTransitionEnd: () => {
          this.navSlider.find(".slide-timer").addClass("active");
        },
      },
    });
  };

  homeBannerSliderInit = () => {
    const t1 = new TimelineMax();
    let flag = 0;
    const homeBannerSlides = new Swiper(
      ".banner-slider .content-slider .swiper-container",
      {
        loop: true,
        slidesPerView: 1,
        speed: 500,
        centeredSlides: true,
        // autoplay: {
        //   delay: 8000,
        //   disableOnInteraction: false,
        // },
        allowTouchMove: false,
        loopedSlides: 1,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: ".banner-slider .swiper-container .swiper-pagination",
          clickable: true,
        },
        on: {
          init: () => {
 //           console.log("on slider init");
            setTimeout(() => {
              t1.staggerFromTo(
                ".banner-slider .swiper-slide-active .heading_line .split-lines",
                1,
                { autoAlpha: 0, y: 80 },
                { autoAlpha: 1, y: -10, ease: Power3.easeOut },
                  0.01,
              );
            }, 1000);
          },
          slideChangeTransitionStart: () => {
            $(
              ".banner-slider .swiper-slide-active .heading_line .split-lines"
            ).css("opacity", 0);
          },
          slideChangeTransitionEnd: () => {
            t1.staggerFromTo(
              ".banner-slider .swiper-slide-active .heading_line .split-lines",
                0.8,
                { autoAlpha: 0, y: 80 },
                { autoAlpha: 1, y: -10, ease: Power3.easeOut },
                0.01,
            );
          },
        },
      }
    );

    const homeimageSlides = new Swiper(
      ".banner-slider .image-slider .swiper-container",
      {
        loop: true,
        slidesPerView: 1,
        speed: 600,
        centeredSlides: true,
        allowTouchMove: false,
        loopedSlides: 1,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      }
    );

    homeimageSlides.on("slideChange", () => {
      let index = homeBannerSlides.realIndex;
    });

    homeBannerSlides.controller.control = homeimageSlides;
    homeimageSlides.controller.control = homeimageSlides;
  };

  homePartnerSliderInit = () => {
    const hPartnerSlider = new Swiper(".partners-slider .swiper-container", {
      loop: true,
      slidesPerView: 1.4,
      spaceBetween: 15,
      speed: 600,
      centeredSlides: true,
      observer: true,
      observeParents: true,
      grabCursor: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },

      breakpoints: {
        1600: {
          slidesPerView: 1.8,
        },
        991: {
          slidesPerView: 1.6,
          spaceBetween: 50,
        },
        767: {
          slidesPerView: 1.4,
          spaceBetween: 25,
        },
      },
    });
  };

  homeEventsSliderInit = () => {
    const upcomingEvents = new Swiper(
      ".tabs-content #upcoming-events .swiper-container",
      {
        //   loop: true,
        slidesPerView: 1.1,
        spaceBetween: 15,
        speed: 1000,
        centeredSlides: false,
        observer: true,
        observeParents: true,
        grabCursor: true,
        autoplay: false,
        runCallbacksOnInit: true,
        breakpoints: {
          1200: {
            slidesPerView: 2.5,
            spaceBetween: 34,
          },
          991: {
            slidesPerView: 1.8,
            spaceBetween: 25,
            // autoplay: {
            //   delay: 7000,
            //   disableOnInteraction: false,
            // },
          },
          768: {
            spaceBetween: 40,
          },
        },
        navigation: {
          nextEl:
            ".tabs-content #upcoming-events .swiper-button-next, .case-study-detail-wrapper .swiper-button-next",
          prevEl:
            ".tabs-content #upcoming-events .swiper-button-prev, .case-study-detail-wrapper .swiper-button-prev",
        },
        pagination: {
          el: "#upcoming-events .swiper-container .swiper-pagination",
          type: "fraction",
        },
        on: {
          slideChange: () => {
            var currentSlides = document.querySelector(
              "#upcoming-events .swiper-container .swiper-pagination-current"
            ).innerHTML;
//            console.log("curent slide is :" + currentSlides);

            var totalSlides = document.querySelector(
              "#upcoming-events .swiper-container .swiper-pagination-total"
            ).innerHTML;
 //           console.log("total slide are :" + totalSlides);

            if (currentSlides > 1) {
              $(".jada-cursor .batch .icon-coolicon_left").css({
                opacity: "1",
              });
            } else {
              $(".jada-cursor .batch .icon-coolicon_left").css({
                opacity: "0.3",
              });
            }
            if (currentSlides === totalSlides) {
              $(".jada-cursor .batch .icon-coolicon_right").css({
                opacity: "0.3",
              });
            } else {
              $(".jada-cursor .batch .icon-coolicon_right").css({
                opacity: "1",
              });
            }
          },
        }
      }
    );

    const pastEvents = new Swiper(
      ".tabs-content #past-events .swiper-container",
      {
        //  loop: true,
        slidesPerView: 1.1,
        spaceBetween: 15,
        speed: 1000,
        centeredSlides: false,
        observer: true,
        observeParents: true,
        grabCursor: true,
        autoplay: false,
        breakpoints: {
          1200: {
            slidesPerView: 2.5,
            spaceBetween: 34,
          },
          991: {
            slidesPerView: 1.8,
            spaceBetween: 25,
            // autoplay: {
            //   delay: 7000,
            //   disableOnInteraction: false,
            // },
          },
          767: {
            spaceBetween: 20,
            slidesPerView: 1.5
          },
        },
        navigation: {
          nextEl: ".tabs-content #past-events .swiper-button-next",
          prevEl: ".tabs-content #past-events .swiper-button-prev",
        },
        pagination: {
          el: "#past-events .swiper-container .swiper-pagination",
          type: "fraction",
        },
        on: {
          slideChange: () => {
            var currentSlides = document.querySelector(
              "#past-events .swiper-container .swiper-pagination-current"
            ).innerHTML;
 //           console.log("curent slide is :" + currentSlides);

            var totalSlides = document.querySelector(
              "#past-events .swiper-container .swiper-pagination-total"
            ).innerHTML;
//            console.log("total slide are :" + totalSlides);

            if (currentSlides > 1) {
              $(".jada-cursor .batch .icon-coolicon_left").css({
                opacity: "1",
              });
            } else {
              $(".jada-cursor .batch .icon-coolicon_left").css({
                opacity: "0.3",
              });
            }
            if (currentSlides === totalSlides) {
              $(".jada-cursor .batch .icon-coolicon_right").css({
                opacity: "0.3",
              });
            } else {
              $(".jada-cursor .batch .icon-coolicon_right").css({
                opacity: "1",
              });
            }
          }
        },
      }
    );

    const el = document.querySelectorAll(".events-section .tab-content .lozad");
    const observer = lozad(el); //passing a `Node list` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe();

    $('.nav-tabs a[data-tab="upcoming-events"]').click(function () {
      upcomingEvents.update();
      observer.observe();
      var currentSlides = document.querySelector(
        "#upcoming-events .swiper-container .swiper-pagination-current"
      ).innerHTML;
//      console.log("curent slide is :" + currentSlides);

      var totalSlides = document.querySelector(
        "#upcoming-events .swiper-container .swiper-pagination-total"
      ).innerHTML;
 //     console.log("total slide are :" + totalSlides);

      if (currentSlides > 1) {
        $(".jada-cursor .batch .icon-coolicon_left").css({
          opacity: "1",
        });
      } else {
        $(".jada-cursor .batch .icon-coolicon_left").css({
          opacity: "0.3",
        });
      }
      if (currentSlides === totalSlides) {
        $(".jada-cursor .batch .icon-coolicon_right").css({
          opacity: "0.3",
        });
      } else {
        $(".jada-cursor .batch .icon-coolicon_right").css({
          opacity: "1",
        });
      }
    });

    $('.nav-tabs a[data-tab="past-events"]').click(function () {
      pastEvents.update();
      observer.observe();
      var currentSlides = document.querySelector(
        "#past-events .swiper-container .swiper-pagination-current"
      ).innerHTML;
//      console.log("curent slide is :" + currentSlides);

      var totalSlides = document.querySelector(
        "#past-events .swiper-container .swiper-pagination-total"
      ).innerHTML;
 //     console.log("total slide are :" + totalSlides);

      if (currentSlides > 1) {
        $(".jada-cursor .batch .icon-coolicon_left").css({
          opacity: "1",
        });
      } else {
        $(".jada-cursor .batch .icon-coolicon_left").css({
          opacity: "0.3",
        });
      }
      if (currentSlides === totalSlides) {
        $(".jada-cursor .batch .icon-coolicon_right").css({
          opacity: "0.3",
        });
      } else {
        $(".jada-cursor .batch .icon-coolicon_right").css({
          opacity: "1",
        });
      }
    });
  };

  homeSocialSlider = () => {
    const socialSlider = new Swiper(".social-gallery .swiper-container", {
      slidesPerView: 1.4,
      spaceBetween: 14,
      speed: 600,
      centeredSlides: true,
      loop: true,
      observer: true,
      observeParents: true,
      grabCursor: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },

      breakpoints: {
        1600: {
          slidesPerView: 4.3,
          loop: true,
          spaceBetween: 40,
        },
        1090: {
          slidesPerView: 3.3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 2,
          //loop:false,
          spaceBetween: 40,
        },
      },
    });

    const el = document.querySelectorAll(
      ".social-section .swiper-slide .lozad"
    );
    const observer = lozad(el); //passing a `Node list` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe();
  };

  // about page sliders
  threeColSliderInit = () => {
    const colSlider = new Swiper(".three-col-block .swiper-container", {
      slidesPerView: 1.1,
      spaceBetween: 20,
      loop: true,
      observer: true,
      observeParents: true,
      grabCursor: true,
      allowTouchMove: true,
      autoplay: false,
      breakpoints: {
        // 768: {
        //   slidesPerView: 1,
        //   spaceBetween: 20,
        // },
        767: {
          slidesPerView: 1.3,
          spaceBetween: 40,
          autoplay: false,
        },
        991: {
          slidesPerView: 1.6,
          spaceBetween: 40,
        },
        1300: {
          slidesPerView: 2,
          spaceBetween: 20,
          grabCursor: false,
          allowTouchMove: false,
        },
        1090: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
      },
    });
  };

  missionVisionSlider = () => {
    const titleSlider = new Swiper(
      ".mission-vision-block .swiper-container.tab-slider",
      {
        slidesPerView: 3,
        allowTouchMove: false,
        speed: 600,
        direction: "vertical",
        loop: true,
        loopedSlides: 3,
        spaceBetween: 0,
        slideToClickedSlide: true,
        breakpoints: {
          991: {
           // slidesPerView: 3,
          },
        },
      }
    );
    const contentSlider = new Swiper(
      ".mission-vision-block .swiper-container.content-slider",
      {
        slidesPerView: 1,
        allowTouchMove: false,
        speed: 600,
        loopedSlides: 3,
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },

        on: {
          init: () => {
            
            setTimeout(() => {
              var contentBlockHeight = $('.mission-vision-block .swiper-container.content-slider .swiper-slide-active p').height();
              $('.mission-vision-block .swiper-container.content-slider').css('height', contentBlockHeight+ 'px');
            },1500);
            setTimeout(() => {
              globalScroll.update();
             
            }, 600);
          },
          slideChange: () => {
            
            setTimeout(() => {
              var contentBlockHeight = $('.mission-vision-block .swiper-container.content-slider .swiper-slide-active p').height();
              $('.mission-vision-block .swiper-container.content-slider').css('height', contentBlockHeight+ 'px');

 //             console.log("change mission-visio height on chnage", contentBlockHeight);
            },1500);
            setTimeout(() => {
              globalScroll.update();
              
            }, 600);
           // 
          }
        }
      }
    );
    titleSlider.controller.control = contentSlider;
    contentSlider.controller.control = titleSlider;
  };

  rangeSliderInit = () => {
    const rSlider = new Swiper(".range-slider .swiper-container", {
      slidesPerView: 1.1,
      spaceBetween: 10,
      simulateTouch: true,
      autoplay: false,
      speed: 600,
      touchRatio: 1,
     // autoPlayDelay: 000,
      allowTouchMove: true,
      breakpoints: {
        767: {
          slidesPerView: 1.2,
          spaceBetween: 40,
        },
        991: {
          slidesPerView: 1.3,
         // allowTouchMove: false,
          simulateTouch: false,
        },
        1200: {
          slidesPerView: 1.4,
          spaceBetween: 60
        },
        1600: {
          slidesPerView: 1.6,
          spaceBetween: 80
        }
      },
      navigation: {
        nextEl: ".range-slider .swiper-button-next",
        prevEl: ".range-slider .swiper-button-prev",
      },
    });
  };


  //funds detail page sliders

  VentureVideoSliderInit = () => {
    const ventureSlider = new Swiper(
      ".ventures-video-slider .swiper-container",
      {
        loop: true,
        slidesPerView: 1.1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        grabCursor: true,

        breakpoints: {
          600: {
            // slidesPerView: 1.5,
            spaceBetween: 40,
          },
          991: {
            slidesPerView: 2.1,
            spaceBetween: 40,
            autoplay: {
              delay: 3000,
            },
          },
        },
      }
    );

    const el = document.querySelectorAll(
      ".ventures-video-slider .swiper-slide .lozad"
    );
    const observer = lozad(el); //passing a `Node list` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe();
  };

  portfolioSliderInit = () => {
    const portfolioSlider = new Swiper(
      ".company-portfolio-slider .swiper-container",
      {
        loop: true,
        slidesPerView: 1.5,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        grabCursor: true,
        // autoplay: {
        //   delay: 3000,
        // },
        breakpoints: {
          767: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
        },
      }
    );

    const el = document.querySelectorAll(
      ".company-portfolio-slider .swiper-slide .lozad"
    );
    const observer = lozad(el); //passing a `Node list` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe();
  };

  VentureSocialSliderInit = () => {
    const socialSlider = new Swiper(
      ".venture-social-slider .swiper-container",
      {
        loop: true,
        slidesPerView: 1.1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        grabCursor: true,
        //centeredSlides: true,
        // autoplay: {
        //   delay: 3000,
        // },
        breakpoints: {
          600: {
            slidesPerView: 1.5,
            spaceBetween: 40,
          },
          991: {
            slidesPerView: 2.1,
            spaceBetween: 40,
          },
        },
      }
    );

    const el = document.querySelectorAll(
      ".venture-social-slider .swiper-slide .lozad"
    );
    const observer = lozad(el); //passing a `Node list` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe();
  };

  //emp sliders
  threeColEmpSliderInit = () => {
    const colEmpSlider = new Swiper(" .three-col-block-emp .swiper-container", {
      slidesPerView: 1,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      grabCursor: true,
      // autoplay: {
      //   delay: 3000,
      // },
      breakpoints: {
        767: {
          slidesPerView: 1.3,
          spaceBetween: 40,
        },
        991: {
          slidesPerView: 1.6,
          spaceBetween: 40,
          autoplay: false,
        },
        1090: {
          slidesPerView: 2.1,
          spaceBetween: 40,
        },
      },
    });
  };

  // case study slider
  caseStudySliderInit = () => {
   // var slides_count = document.getElementsByClassName("swiper-slide");
    var caseStudyEvents = new Swiper(".gallery-inner-wrapper .swiper-container", {
      loop: false,
      slidesPerView: 1.1,
      spaceBetween: 15,
      speed: 1000,
      centeredSlides: true,
      freeMode: false,
      // observer: true,
      // observeParents: true,
      grabCursor: true,
      autoplay: false,
      runCallbacksOnInit: true,
      breakpoints: {
        1660: {
          slidesPerView: 1.5,
        },
        991: {
          slidesPerView: 1.3,
          spaceBetween: 52,
          autoplay: {
            delay: 7000,
            disableOnInteraction: false,
          },
        },
        768: {
          spaceBetween: 40,
        },
      },
      navigation: {
        nextEl: ".gallery-inner-wrapper .swiper-button-next",
        prevEl: ".gallery-inner-wrapper .swiper-button-prev",
      },
      pagination: {
        el: ".gallery-inner-wrapper .swiper-pagination",
        type: "fraction"
      },
      on: {
        slideChange: () => {
          var currentSlides = document.querySelector(
            ".gallery-inner-wrapper .swiper-pagination-current"
          ).innerHTML;
//          console.log("curent slide is :" + currentSlides);

          var totalSlides = document.querySelector(
            ".gallery-inner-wrapper .swiper-pagination-total"
          ).innerHTML;
 //         console.log("total slide are :" + totalSlides);

          if (currentSlides > 1) {
            $(".jada-cursor .batch .icon-coolicon_left").css({
              opacity: "1",
            });
          } else {
            $(".jada-cursor .batch .icon-coolicon_left").css({
              opacity: "0.3",
            });
          }
          if (currentSlides === totalSlides) {
            $(".jada-cursor .batch .icon-coolicon_right").css({
              opacity: "0.3",
            });
          } else {
            $(".jada-cursor .batch .icon-coolicon_right").css({
              opacity: "1",
            });
          }
        }
      },
    });
  };


  careerImagesSliderInit = () => {
    var swiper = new Swiper('.swiper-container.images', {
      slidesPerView: 2.3,
      loop:true,
      speed: 100000,
      spaceBetween: 15,
      allowTouchMove: false,
      breakpoints: {
        991: {
          spaceBetween: 63,
          slidesPerView: 4,
        },
        767: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      }
    });
    function infinite() {
      swiper.slideTo(swiper.slides.length);
      swiper.once('transitionEnd', function(){
        swiper.slideTo(swiper.params.slidesPerView, 0, false);
        setTimeout(function () {
          infinite();
        }, 0);
      });
    }
    infinite();
  }
}
