export default class Tabs {
  constructor() {
    this.$tabs = $(".dashboard-tabs");
    this.$tabContent = $(".dashboard-tabs__content");
   this.bindEvents();
  }

  bindEvents = () => {
    this.$tabs
      .find("li")
      .off()
      .on("click", (e) => {
        const $this = $(e.currentTarget);
        $this.siblings().removeClass("active");
        $this.addClass("active");
        this.$tabContent
          .addClass("hidden")
          .eq($this.index())
          .removeClass("hidden");
      });
  };
}
