export default class Forms {
    constructor() {
        this.formPreCheck = $('.form-precheck');
        this.cboxWrap = this.formPreCheck.find('.form-precheck--cbox-container');
        this.cbox = this.cboxWrap.find('input[type="checkbox"]');
        console.log(this.cbox)
        this.formMain = $('.form-funding-main');
        this.formUnlockTrigger = this.formPreCheck.find('#form-unlock-trigger');
        this.mainSubmitBtn = this.formMain.find('#ctl00_PlaceHolderMain_ctl01_JADAFunding_ctl00_btnSubmit');
        this.bindEvents();
    }

    bindEvents = () => {
        this.cbox.on('change', this.updateCheckState);
        this.updateMainForm();
        if(localStorage.getItem('checkboxesChecked') == "1"){
            this.formPreCheck.addClass('unlock');
            this.formMain.removeClass('disabled');
         }
    };

    updateCheckState = () => {
        const allChecked = this.cbox.toArray().every(checkbox => checkbox.checked);
        if (allChecked) {
            this.unlockForm();
            this.formUnlockTrigger.on('click', () =>{
                this.formPreCheck.addClass('unlock')
                this.formMain.removeClass('disabled');
                let checkedItem = 0;

                $('.form-precheck .form-precheck--cbox-container input').each((index,item) => {
                    if($(item)[0].checked){
                        checkedItem++;
                    }
                })

                if(checkedItem == 9) {
                    localStorage.setItem('checkboxesChecked', "1");
                }
            });
            return true;
        } else {
            if (!this.formUnlockTrigger.hasClass("disabled")) {
            this.formUnlockTrigger.addClass('disabled');
            return false;
            }
        }

    }
    unlockForm = () => {
        this.formUnlockTrigger.removeClass('disabled');
        this.mainSubmitBtn.addClass("disabled");
    }

    updateMainForm = () => {
        $('.form-funding-main .flex input[type="checkbox"]').change(function () {
            const checkBoxChecked = $('.form-funding-main .flex input[type="checkbox"]:checked').length > 0;
            if (checkBoxChecked) {
               $('#ctl00_PlaceHolderMain_ctl01_JADAFunding_ctl00_btnSubmit').removeClass("disabled");
            } else {
                $('#ctl00_PlaceHolderMain_ctl01_JADAFunding_ctl00_btnSubmit').addClass("disabled");
            }
        });
    }

};