import Locomotive from "locomotive-scroll/dist/locomotive-scroll";
import bodymovin from "lottie-web";




$.fn.isInViewportOffset = function () {
  if($(this).length) {
    const elementTop = $(this).offset().top - 500;
    const elementBottom = elementTop + $(this).outerHeight() + 500;
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
};

export default class locomotive {
  constructor({ window, header, htmlBody, sideMenu }) {
    this.window = window;
    this.header = header;
    this.htmlBody = htmlBody;
    this.document = $(document);
    this.scroll = null;
    this.sideMenu = sideMenu;
    this.featuredEvent = $(".featured-event-card");
    this.dashboardPage = $(".dashboard-page");
    this.contactPage = $(".contact-us-wrapper");
    this.registerPage = $(".login-regsiter_wrapper");
    this.init();
  }

  init = () => {
    let isloggedin = $(".editmode").length;
    let isMobile = navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/);
    globalScroll = scroll = new Locomotive({
      el: document.querySelector(".smooth-scroll"),
      smooth: false,
      multiplier: 1.4,
      getDirection: true,
      offset: isMobile ? ["0%", "0%"] : ["0%", "0%"],
      tablet: { smooth: false },
      smartphone: { smooth: false },
    });

    let isSmooth = $("html").hasClass("has-scroll-smooth");

    if (isSmooth) {
      this.handleStickyHeader();
    } else {
      this.window.scroll(this.windowScroll);
    }

    if ($(window).width() >= 991) {
      this.lottieAnimDesktop();
    } else {
      this.lottieAnimMobile();
    }

    if (this.dashboardPage.length && this.registerPage.length) {
      // this.loadResources();
    }
    // this.economicSectionScroll();
    this.eventsFeaturedSection();

    this.dropDownFix();
    this.fixedPos();
    // this.parallaxBg();
  };

  handleStickyHeader = () => {
    this.screenWidth = this.window.width();
    this.ScreenHeight = this.window.height();

    globalScroll.on("scroll", (args) => {
      if (args.direction == "up") {
        if (args.delta.y >= 50) {
          $("header").removeClass("top").addClass("sticky");
          $(".side-bar-hamburger").removeClass("top").addClass("sticky");
        }
      } else {
        if (args.delta.y > 50) {
          $("header").removeClass("sticky").addClass("top");
          $(".side-bar-hamburger").removeClass("sticky").addClass("top");
        }
      }

      if (args.delta.y == 0) {
        $("header").removeClass("sticky").removeClass("top");
        $(".side-bar-hamburger").removeClass("sticky").removeClass("top");
        $(".breadcrumb-container").removeClass("fade");
      }

      if (args.delta.y > 10) {
        $(".breadcrumb-container").addClass("fade");
      }
    });
  };

  windowScroll = () => {
    const topOffset = this.window.scrollTop();
    console.log("windowScroll");

    this.header.toggleClass("top", topOffset > 300);
    this.sideMenu.toggleClass("top", topOffset > 300);
    this.htmlBody.toggleClass("top", topOffset > 300);
    this.header.toggleClass("sticky", topOffset > 600);
    this.sideMenu.toggleClass("sticky", topOffset > 600);
    if (topOffset > this.previousScroll || topOffset < 500) {
      this.header.removeClass("sticky");
      this.sideMenu.removeClass("sticky");
    } else if (topOffset < this.previousScroll) {
      this.header.addClass("sticky");
      this.sideMenu.addClass("sticky");
      if (topOffset > 250) {
        this.header.addClass("sticky");
        this.sideMenu.addClass("sticky");
      } else {
        this.header.removeClass("sticky");
        this.sideMenu.removeClass("sticky");
      }
    }
    this.previousScroll = topOffset;
  };

  lottieAnimDesktop = () => {
    //  console.log('desktopAnim')
    if ($(".stats-section").length) {
      let fundsAnimated = bodymovin.loadAnimation({
        container: document.getElementById("svg_01"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 116,
          h: 116,
          nm: "7 Funds",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [62.087, 10.176, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.966, 7.965, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-3.142, 0],
                                [0, -3.143],
                                [3.142, 0],
                                [0, 3.142],
                              ],
                              o: [
                                [3.142, 0],
                                [0, 3.142],
                                [-3.142, 0],
                                [0, -3.143],
                              ],
                              v: [
                                [0, -5.689],
                                [5.69, 0.001],
                                [0, 5.689],
                                [-5.69, 0.001],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.966, 7.966], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [41.604, 20.418, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.104, 9.104, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-3.771, 0],
                                [0, -3.771],
                                [3.771, 0],
                                [0, 3.77],
                              ],
                              o: [
                                [3.771, 0],
                                [0, 3.77],
                                [-3.771, 0],
                                [0, -3.771],
                              ],
                              v: [
                                [0, -6.827],
                                [6.828, 0.001],
                                [0, 6.827],
                                [-6.828, 0.001],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.104, 9.104], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [60.379, 34.642, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [10.81, 10.811, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-4.713, 0],
                                [0, -4.713],
                                [4.713, 0],
                                [0, 4.714],
                              ],
                              o: [
                                [4.713, 0],
                                [0, 4.714],
                                [-4.713, 0],
                                [0, -4.713],
                              ],
                              v: [
                                [0, -8.535],
                                [8.534, -0.001],
                                [0, 8.535],
                                [-8.534, -0.001],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [10.81, 10.81], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [17.201, 75.008, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.062, 16.484, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.452, 14.875],
                                [5.452, 14.875],
                                [5.452, -14.875],
                                [-5.452, -14.875],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.062, 16.484], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [64.191, 73.265, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [43.492, 16.041, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-0.426, -2.048],
                                [0, 0],
                                [1.9, -0.866],
                                [0, 0],
                                [1.887, 0.002],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [2.052, -0.335],
                                [0, 0],
                                [0.424, 2.041],
                                [0, 0],
                                [-1.721, 0.784],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.938, -8.792],
                                [36.335, -13.43],
                                [40.818, -10.34],
                                [40.792, -10.468],
                                [38.134, -5.211],
                                [-0.409, 12.347],
                                [-6.937, 13.763],
                                [-41.216, 13.731],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [43.492, 16.041], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.559, 60.541, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [27.134, 9.732, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-1.816, -0.495],
                                [0, 0],
                                [0.675, -2.179],
                                [0, 0],
                                [2.256, 0.157],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [1.638, -0.931],
                                [0, 0],
                                [2.182, 0.595],
                                [0, 0],
                                [-0.673, 2.175],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-24.859, 5.033],
                                [-5.137, -6.174],
                                [1.128, -6.96],
                                [21.452, -1.415],
                                [24.184, 3.6],
                                [24.169, 3.646],
                                [18.853, 7.299],
                                [2.215, 6.141],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [27.134, 9.732], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "funds",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [58, 58, 0], ix: 2, l: 2 },
                a: { a: 0, k: [58, 48.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 116,
              h: 97,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('funds-animated')

      let capital1 = bodymovin.loadAnimation({
        container: document.getElementById("svg_02"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 86,
          h: 86,
          nm: "1 committed",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 9 Outlines",
                  sr: 1,
                  ks: {
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          t: 24,
                          s: [0],
                        },
                        { t: 52, s: [100] },
                      ],
                      ix: 11,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                    },
                    r: { a: 0, k: 180, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.833, y: 0.833 },
                          o: { x: 0.167, y: 0.167 },
                          t: 16,
                          s: [41.943, 5, 0],
                          to: [0, 1.363, 0],
                          ti: [0, -1.363, 0],
                        },
                        { t: 55.599609375, s: [41.943, 13.179, 0] },
                      ],
                      ix: 2,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      l: 2,
                    },
                    a: { a: 0, k: [11.043, 13.679, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.514, -2.636],
                                [-3.514, 13.179],
                                [3.515, 13.179],
                                [3.515, -2.636],
                                [10.543, -2.636],
                                [0, -13.179],
                                [-10.543, -2.636],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0.5, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 13.679], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-0.999, -12.678],
                                [-10.544, -3.135],
                                [-3.514, -3.135],
                                [-3.514, 12.678],
                                [3.514, 12.678],
                                [3.514, -3.135],
                                [10.544, -3.135],
                                [1.001, -12.678],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 14.178], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 2",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 2,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 5,
                            s: [0],
                          },
                          { t: 54.2001953125, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.452, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.363],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 44.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.451, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.398], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "deployed 2",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [43, 43, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 43.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 87,
              h: 87,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      // console.log('capital1-animated')

      let derivative = bodymovin.loadAnimation({
        container: document.getElementById("svg_03"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 100,
          h: 100,
          nm: "4 Derivative",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [48.5, 66.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.75, 1.75, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-0.828, 0],
                                [0, -0.828],
                                [0.828, 0],
                                [0, 0.828],
                              ],
                              o: [
                                [0.828, 0],
                                [0, 0.828],
                                [-0.828, 0],
                                [0, -0.828],
                              ],
                              v: [
                                [0, -1.5],
                                [1.5, 0],
                                [0, 1.5],
                                [-1.5, 0],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.75, 1.75], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.5, 66.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.75, 1.75, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-0.828, 0],
                                [0, -0.828],
                                [0.828, 0],
                                [0, 0.828],
                              ],
                              o: [
                                [0.828, 0],
                                [0, 0.828],
                                [-0.828, 0],
                                [0, -0.828],
                              ],
                              v: [
                                [0, -1.5],
                                [1.5, 0],
                                [0, 1.5],
                                [-1.5, 0],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.75, 1.75], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.086, 7.38, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [15.855, 7.579, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.379, 5.379],
                                [-13.655, -5.379],
                                [0.414, -2.069],
                                [13.655, -5.379],
                                [6.207, 5.379],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 2,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [15.855, 7.579], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.048, 51.724, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.579, 12.545, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 5.44],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.379, -10.345],
                                [-5.379, 10.345],
                                [5.379, 10.345],
                                [5.377, 2.896],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.579, 12.545], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [27.775, 56.689, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.993, 17.51, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0.001, 9.93],
                              ],
                              o: [
                                [0, 0],
                                [0, 7.448],
                                [0, 0],
                              ],
                              v: [
                                [5.793, -15.31],
                                [5.793, 7.862],
                                [-5.793, 5.38],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.993, 17.51], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.499, 54.449, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [35.031, 41.651, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, -10.971],
                                [5.88, -5.359],
                                [9.158, 0],
                                [5.896, 5.375],
                                [0, 10.934],
                                [-6.022, 8.904],
                                [-8.855, 0],
                                [-6.037, -8.927],
                              ],
                              o: [
                                [0, 10.934],
                                [-5.896, 5.375],
                                [-9.158, 0],
                                [-5.879, -5.359],
                                [0, -10.971],
                                [6.037, -8.927],
                                [8.855, 0],
                                [6.021, 8.904],
                              ],
                              v: [
                                [32.831, 6.965],
                                [23.252, 31.344],
                                [0, 39.452],
                                [-23.252, 31.344],
                                [-32.831, 6.965],
                                [-23.081, -24.576],
                                [0, -39.452],
                                [23.082, -24.576],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [35.031, 41.651], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 2 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [55.638, 66.206, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [11.027, 14.613, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [14.565, -0.662],
                              ],
                              o: [
                                [0.276, 8],
                                [0, 0],
                              ],
                              v: [
                                [8.551, -12.413],
                                [-8.827, 12.413],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.027, 14.613], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "derivative",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43, 48, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 86,
              h: 96,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('derivative-animated')

      let capital2 = bodymovin.loadAnimation({
        container: document.getElementById("svg_04"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 86,
          h: 86,
          nm: "3 deployed new",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 9 Outlines",
                  sr: 1,
                  ks: {
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          t: 24,
                          s: [0],
                        },
                        { t: 52, s: [100] },
                      ],
                      ix: 11,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                    },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.833, y: 0.833 },
                          o: { x: 0.167, y: 0.167 },
                          t: 16,
                          s: [41.943, 20, 0],
                          to: [0, -1.137, 0],
                          ti: [0, 1.137, 0],
                        },
                        { t: 55.599609375, s: [41.943, 13.179, 0] },
                      ],
                      ix: 2,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      l: 2,
                    },
                    a: { a: 0, k: [11.043, 13.679, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.514, -2.636],
                                [-3.514, 13.179],
                                [3.515, 13.179],
                                [3.515, -2.636],
                                [10.543, -2.636],
                                [0, -13.179],
                                [-10.543, -2.636],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0.5, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 13.679], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-0.999, -12.678],
                                [-10.544, -3.135],
                                [-3.514, -3.135],
                                [-3.514, 12.678],
                                [3.514, 12.678],
                                [3.514, -3.135],
                                [10.544, -3.135],
                                [1.001, -12.678],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 14.178], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 2",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 2,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 5,
                            s: [0],
                          },
                          { t: 54.2001953125, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.452, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.363],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 44.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.451, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.398], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "deployed 3",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [43, 43, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 43.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 87,
              h: 87,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('capital-animated')

      let jobs = bodymovin.loadAnimation({
        container: document.getElementById("svg_05"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 116,
          h: 86,
          nm: "5 Jons",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40, 46, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.456, 6.456, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.9, -4.9],
                                [5.9, -4.9],
                                [5.9, 4.9],
                                [-5.9, 4.9],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.456, 6.456], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [50.01, 3.423, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.35, 3.673, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.1, 3.423],
                                [1.1, 3.423],
                                [1.1, -3.423],
                                [-1.1, -3.423],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.35, 3.673], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40.054, 1.1, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.105, 1.351, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-8.855, 1.1],
                                [8.855, 1.1],
                                [8.855, -1.1],
                                [-8.855, -1.1],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.105, 1.351], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [30.1, 3.861, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.35, 4.11, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.1, 3.861],
                                [1.1, 3.861],
                                [1.1, -3.861],
                                [-1.1, -3.861],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.35, 4.111], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40.042, 54.179, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [36.63, 15.119, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [0.148, -8.262],
                                [34.43, -12.919],
                                [34.43, 12.919],
                                [-34.43, 12.919],
                                [-34.43, -12.919],
                                [-0.148, -8.262],
                                [0, -8.242],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [36.63, 15.119], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 2 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40.042, 26.577, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [41.142, 21.398, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-38.942, -19.198],
                                [38.942, -19.198],
                                [38.942, 13.932],
                                [0, 19.198],
                                [-38.942, 13.932],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [41.142, 21.398], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "jobs",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [58, 43, 0], ix: 2, l: 2 },
                a: { a: 0, k: [40.5, 34.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 81,
              h: 69,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('jobs-animated')

      let smes = bodymovin.loadAnimation({
        container: document.getElementById("svg_06"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 100,
          h: 100,
          nm: "3 SMEs",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [66.015, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [54.443, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [42.869, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [31.297, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.934, 21.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.034, 22.6, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-17.361, -3.2],
                                [27.835, -3.2],
                                [27.835, 20.4],
                                [-27.835, 20.4],
                                [-27.835, -20.4],
                                [-18.46, -20.4],
                                [-18.46, -4.3],
                                [-18.46, -3.2],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.034, 22.6], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 2 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [68.486, 12.764, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.497, 7.622, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.297, 5.422],
                                [7.297, -5.422],
                                [-7.297, 5.422],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.497, 7.622], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 14 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [53.891, 12.763, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.497, 7.622, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.297, 5.422],
                                [7.297, -5.422],
                                [-7.297, 5.422],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.497, 7.622], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 8,
                  ty: 4,
                  nm: "Layer 13 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [39.297, 12.763, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.497, 7.622, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.297, 5.422],
                                [7.297, -5.422],
                                [-7.297, 5.422],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.497, 7.622], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: { a: 0, k: 100, ix: 2 },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 3,
                      nm: "Trim Paths 2",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 9,
                  ty: 4,
                  nm: "Layer 12 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [74.456, 92.082, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [10.991, 4.973, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-9.435, 3.417],
                                [9.435, 3.417],
                                [9.435, -3.417],
                                [-9.435, -3.417],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [10.991, 4.973], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 10,
                  ty: 4,
                  nm: "Layer 11 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [80.61, 62.953, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.327, 27.715, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-1.303, -0.231],
                                [0, 0],
                                [-0.058, -1.307],
                                [0, 0],
                                [0.448, -1.097],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0.29, -1.272],
                                [0, 0],
                                [1.3, 0.231],
                                [0, 0],
                                [0.053, 1.185],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.128, -3.779],
                                [-1.355, -23.399],
                                [1.522, -25.284],
                                [1.441, -25.298],
                                [3.9, -22.523],
                                [5.074, 3.998],
                                [4.359, 8.126],
                                [-0.382, 25.515],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.327, 27.715], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 11,
                  ty: 4,
                  nm: "Layer 10 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [69.787, 72.486, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [8.523, 18.184, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-0.534, 1.19],
                                [0, 0],
                                [-1.432, -0.686],
                                [0, 0],
                                [0.347, -1.525],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.468, -1.215],
                                [0, 0],
                                [0.641, -1.429],
                                [0, 0],
                                [1.429, 0.685],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-2.59, 15.984],
                                [-5.855, 3.73],
                                [-5.734, -0.632],
                                [0.241, -13.95],
                                [3.989, -15.298],
                                [4.019, -15.283],
                                [5.976, -11.273],
                                [3.417, -0.022],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [8.523, 18.184], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 12,
                  ty: 4,
                  nm: "Layer 9 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [20.544, 92.082, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [10.991, 4.973, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [9.435, 3.417],
                                [-9.435, 3.417],
                                [-9.435, -3.417],
                                [9.435, -3.417],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [10.991, 4.973], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 13,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [14.39, 62.953, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.327, 27.715, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [1.303, -0.231],
                                [0, 0],
                                [0.058, -1.307],
                                [0, 0],
                                [-0.448, -1.097],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.29, -1.272],
                                [0, 0],
                                [-1.3, 0.231],
                                [0, 0],
                                [-0.053, 1.185],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [5.128, -3.779],
                                [1.355, -23.399],
                                [-1.523, -25.284],
                                [-1.441, -25.298],
                                [-3.9, -22.523],
                                [-5.074, 3.998],
                                [-4.359, 8.126],
                                [0.382, 25.515],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.327, 27.715], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 14,
                  ty: 4,
                  nm: "Layer 1 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [25.213, 72.486, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [8.523, 18.184, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0.534, 1.19],
                                [0, 0],
                                [1.432, -0.686],
                                [0, 0],
                                [-0.347, -1.525],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0.469, -1.215],
                                [0, 0],
                                [-0.641, -1.429],
                                [0, 0],
                                [-1.429, 0.685],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [2.59, 15.984],
                                [5.855, 3.73],
                                [5.734, -0.632],
                                [-0.242, -13.95],
                                [-3.99, -15.298],
                                [-4.02, -15.283],
                                [-5.977, -11.273],
                                [-3.417, -0.022],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [8.523, 18.184], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "smes",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [48, 48.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 96,
              h: 97,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      // console.log('smes');

      let emp = bodymovin.loadAnimation({
        container: document.getElementById("svg_07"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 86,
          h: 98,
          nm: "6 EMP",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [21, 29, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [12.1, 12.1, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-5.468, 0],
                                [0, -5.468],
                                [5.468, 0],
                                [0, 5.468],
                              ],
                              o: [
                                [5.468, 0],
                                [0, 5.468],
                                [-5.468, 0],
                                [0, -5.468],
                              ],
                              v: [
                                [0, -9.9],
                                [9.9, 0],
                                [0, 9.9],
                                [-9.9, 0],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [12.1, 12.1], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [51.5, 30.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [19.2, 11.2, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [27.7, 20.2],
                                [36.2, 20.2],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [8.2, 11.2],
                                [36.2, 11.2],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [2.2, 2.2],
                                [36.2, 2.2],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 5,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.75, 27, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.45, 24.2, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [21.75, -24.5],
                                [21.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-20.75, -19.5],
                                [21.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-20.75, -24.5],
                                [-20.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [14.25, 19.5],
                                [28.25, 19.5],
                                [28.25, -19.5],
                                [21.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.25, -10.5],
                                [-28.25, -19.5],
                                [-20.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.45, 26.7], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 7,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [31.25, 62, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [23.95, 23.2, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-21.75, 21],
                                [-1.25, 21],
                                [-1.25, -5],
                                [21.75, -12],
                                [21.75, -21],
                                [-21.75, -21],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [23.95, 23.2], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "emp trainees",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [43, 49, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 43.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 87,
              h: 87,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });

      globalScroll.on("scroll", (args) => {
        if ($(".stat-block__main").hasClass("is-inview")) {
          if (!$(".stat-block__main").hasClass("animated3")) {
            $(".stat-block__main").addClass("animated3");
            fundsAnimated.play();
            //     console.log("lottie detect");
          }
        }

        if ($(".Capital-1").hasClass("is-inview")) {
          if (!$(".Capital-1").hasClass("animated3")) {
            $(".Capital-1").addClass("animated3");

            setTimeout(function () {
              capital1.play();
            }, 150);

            //      console.log("lottie detect 2");
          }
        }

        if ($(".derivative").hasClass("is-inview")) {
          if (!$(".derivative").hasClass("animated3")) {
            $(".derivative").addClass("animated3");

            setTimeout(function () {
              derivative.play();
            }, 300);

            //     console.log("lottie detect 2");
          }
        }

        if ($(".capital-2").hasClass("is-inview")) {
          if (!$(".capital-2").hasClass("animated3")) {
            $(".capital-2").addClass("animated3");

            setTimeout(function () {
              capital2.play();
            }, 450);

            //        console.log("lottie detect 3");
          }
        }

        if ($(".jobs").hasClass("is-inview")) {
          if (!$(".jobs").hasClass("animated3")) {
            $(".jobs").addClass("animated3");

            setTimeout(function () {
              jobs.play();
            }, 600);

            //       console.log("lottie detect 3");
          }
        }

        if ($(".smes").hasClass("is-inview")) {
          if (!$(".smes").hasClass("animated3")) {
            $(".smes").addClass("animated3");

            setTimeout(function () {
              smes.play();
            }, 750);

            //        console.log("lottie detect 3");
          }
        }

        if ($(".emp").hasClass("is-inview")) {
          if (!$(".emp").hasClass("animated3")) {
            $(".emp").addClass("animated3");

            setTimeout(function () {
              emp.play();
            }, 900);

            //       console.log("lottie detect 3");
          }
        }
      });
    }
  };

  lottieAnimMobile = () => {
    if ($(".stats-section").length) {
      let fundsAnimated = bodymovin.loadAnimation({
        container: document.getElementById("svg_01"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 116,
          h: 116,
          nm: "7 Funds",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [62.087, 10.176, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.966, 7.965, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-3.142, 0],
                                [0, -3.143],
                                [3.142, 0],
                                [0, 3.142],
                              ],
                              o: [
                                [3.142, 0],
                                [0, 3.142],
                                [-3.142, 0],
                                [0, -3.143],
                              ],
                              v: [
                                [0, -5.689],
                                [5.69, 0.001],
                                [0, 5.689],
                                [-5.69, 0.001],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.966, 7.966], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [41.604, 20.418, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.104, 9.104, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-3.771, 0],
                                [0, -3.771],
                                [3.771, 0],
                                [0, 3.77],
                              ],
                              o: [
                                [3.771, 0],
                                [0, 3.77],
                                [-3.771, 0],
                                [0, -3.771],
                              ],
                              v: [
                                [0, -6.827],
                                [6.828, 0.001],
                                [0, 6.827],
                                [-6.828, 0.001],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.104, 9.104], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [60.379, 34.642, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [10.81, 10.811, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-4.713, 0],
                                [0, -4.713],
                                [4.713, 0],
                                [0, 4.714],
                              ],
                              o: [
                                [4.713, 0],
                                [0, 4.714],
                                [-4.713, 0],
                                [0, -4.713],
                              ],
                              v: [
                                [0, -8.535],
                                [8.534, -0.001],
                                [0, 8.535],
                                [-8.534, -0.001],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [10.81, 10.81], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [17.201, 75.008, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.062, 16.484, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.452, 14.875],
                                [5.452, 14.875],
                                [5.452, -14.875],
                                [-5.452, -14.875],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.062, 16.484], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [64.191, 73.265, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [43.492, 16.041, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-0.426, -2.048],
                                [0, 0],
                                [1.9, -0.866],
                                [0, 0],
                                [1.887, 0.002],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [2.052, -0.335],
                                [0, 0],
                                [0.424, 2.041],
                                [0, 0],
                                [-1.721, 0.784],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.938, -8.792],
                                [36.335, -13.43],
                                [40.818, -10.34],
                                [40.792, -10.468],
                                [38.134, -5.211],
                                [-0.409, 12.347],
                                [-6.937, 13.763],
                                [-41.216, 13.731],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [43.492, 16.041], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.559, 60.541, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [27.134, 9.732, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-1.816, -0.495],
                                [0, 0],
                                [0.675, -2.179],
                                [0, 0],
                                [2.256, 0.157],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [1.638, -0.931],
                                [0, 0],
                                [2.182, 0.595],
                                [0, 0],
                                [-0.673, 2.175],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-24.859, 5.033],
                                [-5.137, -6.174],
                                [1.128, -6.96],
                                [21.452, -1.415],
                                [24.184, 3.6],
                                [24.169, 3.646],
                                [18.853, 7.299],
                                [2.215, 6.141],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.276, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [27.134, 9.732], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "funds",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [58, 58, 0], ix: 2, l: 2 },
                a: { a: 0, k: [58, 48.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 116,
              h: 97,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('funds-animated')

      let capital1 = bodymovin.loadAnimation({
        container: document.getElementById("svg_02"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 86,
          h: 86,
          nm: "1 committed",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 9 Outlines",
                  sr: 1,
                  ks: {
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          t: 24,
                          s: [0],
                        },
                        { t: 52, s: [100] },
                      ],
                      ix: 11,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                    },
                    r: { a: 0, k: 180, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.833, y: 0.833 },
                          o: { x: 0.167, y: 0.167 },
                          t: 16,
                          s: [41.943, 5, 0],
                          to: [0, 1.363, 0],
                          ti: [0, -1.363, 0],
                        },
                        { t: 55.599609375, s: [41.943, 13.179, 0] },
                      ],
                      ix: 2,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      l: 2,
                    },
                    a: { a: 0, k: [11.043, 13.679, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.514, -2.636],
                                [-3.514, 13.179],
                                [3.515, 13.179],
                                [3.515, -2.636],
                                [10.543, -2.636],
                                [0, -13.179],
                                [-10.543, -2.636],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0.5, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 13.679], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-0.999, -12.678],
                                [-10.544, -3.135],
                                [-3.514, -3.135],
                                [-3.514, 12.678],
                                [3.514, 12.678],
                                [3.514, -3.135],
                                [10.544, -3.135],
                                [1.001, -12.678],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 14.178], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 2",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 2,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 5,
                            s: [0],
                          },
                          { t: 54.2001953125, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.452, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.363],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 44.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.451, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.398], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "deployed 2",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [43, 43, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 43.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 87,
              h: 87,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      // console.log('capital1-animated')

      let derivative = bodymovin.loadAnimation({
        container: document.getElementById("svg_03"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 100,
          h: 100,
          nm: "4 Derivative",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [48.5, 66.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.75, 1.75, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-0.828, 0],
                                [0, -0.828],
                                [0.828, 0],
                                [0, 0.828],
                              ],
                              o: [
                                [0.828, 0],
                                [0, 0.828],
                                [-0.828, 0],
                                [0, -0.828],
                              ],
                              v: [
                                [0, -1.5],
                                [1.5, 0],
                                [0, 1.5],
                                [-1.5, 0],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.75, 1.75], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.5, 66.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.75, 1.75, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-0.828, 0],
                                [0, -0.828],
                                [0.828, 0],
                                [0, 0.828],
                              ],
                              o: [
                                [0.828, 0],
                                [0, 0.828],
                                [-0.828, 0],
                                [0, -0.828],
                              ],
                              v: [
                                [0, -1.5],
                                [1.5, 0],
                                [0, 1.5],
                                [-1.5, 0],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.75, 1.75], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.086, 7.38, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [15.855, 7.579, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.379, 5.379],
                                [-13.655, -5.379],
                                [0.414, -2.069],
                                [13.655, -5.379],
                                [6.207, 5.379],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 2,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [15.855, 7.579], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.048, 51.724, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.579, 12.545, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 5.44],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.379, -10.345],
                                [-5.379, 10.345],
                                [5.379, 10.345],
                                [5.377, 2.896],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.579, 12.545], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [27.775, 56.689, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.993, 17.51, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0.001, 9.93],
                              ],
                              o: [
                                [0, 0],
                                [0, 7.448],
                                [0, 0],
                              ],
                              v: [
                                [5.793, -15.31],
                                [5.793, 7.862],
                                [-5.793, 5.38],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.993, 17.51], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.499, 54.449, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [35.031, 41.651, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, -10.971],
                                [5.88, -5.359],
                                [9.158, 0],
                                [5.896, 5.375],
                                [0, 10.934],
                                [-6.022, 8.904],
                                [-8.855, 0],
                                [-6.037, -8.927],
                              ],
                              o: [
                                [0, 10.934],
                                [-5.896, 5.375],
                                [-9.158, 0],
                                [-5.879, -5.359],
                                [0, -10.971],
                                [6.037, -8.927],
                                [8.855, 0],
                                [6.021, 8.904],
                              ],
                              v: [
                                [32.831, 6.965],
                                [23.252, 31.344],
                                [0, 39.452],
                                [-23.252, 31.344],
                                [-32.831, 6.965],
                                [-23.081, -24.576],
                                [0, -39.452],
                                [23.082, -24.576],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [35.031, 41.651], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 2 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [55.638, 66.206, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [11.027, 14.613, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [14.565, -0.662],
                              ],
                              o: [
                                [0.276, 8],
                                [0, 0],
                              ],
                              v: [
                                [8.551, -12.413],
                                [-8.827, 12.413],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.027, 14.613], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "derivative",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43, 48, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 86,
              h: 96,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('derivative-animated')

      let capital2 = bodymovin.loadAnimation({
        container: document.getElementById("svg_04"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 86,
          h: 86,
          nm: "3 deployed new",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 9 Outlines",
                  sr: 1,
                  ks: {
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          t: 24,
                          s: [0],
                        },
                        { t: 52, s: [100] },
                      ],
                      ix: 11,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                    },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.833, y: 0.833 },
                          o: { x: 0.167, y: 0.167 },
                          t: 16,
                          s: [41.943, 20, 0],
                          to: [0, -1.137, 0],
                          ti: [0, 1.137, 0],
                        },
                        { t: 55.599609375, s: [41.943, 13.179, 0] },
                      ],
                      ix: 2,
                      x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      l: 2,
                    },
                    a: { a: 0, k: [11.043, 13.679, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.514, -2.636],
                                [-3.514, 13.179],
                                [3.515, 13.179],
                                [3.515, -2.636],
                                [10.543, -2.636],
                                [0, -13.179],
                                [-10.543, -2.636],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0.5, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 13.679], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-0.999, -12.678],
                                [-10.544, -3.135],
                                [-3.514, -3.135],
                                [-3.514, 12.678],
                                [3.514, 12.678],
                                [3.514, -3.135],
                                [10.544, -3.135],
                                [1.001, -12.678],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [11.043, 14.178], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 2",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 2,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 5,
                            s: [0],
                          },
                          { t: 54.2001953125, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 32.548, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 51.217, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.397], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.452, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.252, 19.347, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.052, -5.012],
                                [-28.052, 6.243],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [28.052, 5.363],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [28.052, -5.012],
                                [-1.143, -17.148],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-1.143, 17.148],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [-28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 5,
                          ty: "sh",
                          ix: 6,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, 6.596],
                                [28.052, -5.012],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 6",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.252, 19.347], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 8,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 44.400390625, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [43.052, 68.451, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [28.302, 17.397, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.143, -17.148],
                                [28.052, -5.012],
                                [28.052, 5.365],
                                [-1.143, 17.148],
                                [-28.052, 6.243],
                                [-28.052, -5.012],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [28.302, 17.398], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "deployed 3",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [43, 43, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 43.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 87,
              h: 87,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('capital-animated')

      let jobs = bodymovin.loadAnimation({
        container: document.getElementById("svg_05"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 116,
          h: 86,
          nm: "5 Jons",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40, 46, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.456, 6.456, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.9, -4.9],
                                [5.9, -4.9],
                                [5.9, 4.9],
                                [-5.9, 4.9],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.456, 6.456], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [50.01, 3.423, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.35, 3.673, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.1, 3.423],
                                [1.1, 3.423],
                                [1.1, -3.423],
                                [-1.1, -3.423],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.35, 3.673], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40.054, 1.1, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.105, 1.351, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-8.855, 1.1],
                                [8.855, 1.1],
                                [8.855, -1.1],
                                [-8.855, -1.1],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.105, 1.351], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [30.1, 3.861, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [1.35, 4.11, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-1.1, 3.861],
                                [1.1, 3.861],
                                [1.1, -3.861],
                                [-1.1, -3.861],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [1.35, 4.111], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40.042, 54.179, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [36.63, 15.119, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [0.148, -8.262],
                                [34.43, -12.919],
                                [34.43, 12.919],
                                [-34.43, 12.919],
                                [-34.43, -12.919],
                                [-0.148, -8.262],
                                [0, -8.242],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [36.63, 15.119], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 2 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [40.042, 26.577, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [41.142, 21.398, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-38.942, -19.198],
                                [38.942, -19.198],
                                [38.942, 13.932],
                                [0, 19.198],
                                [-38.942, 13.932],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [41.142, 21.398], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "jobs",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [58, 43, 0], ix: 2, l: 2 },
                a: { a: 0, k: [40.5, 34.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 81,
              h: 69,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      //console.log('jobs-animated')

      let smes = bodymovin.loadAnimation({
        container: document.getElementById("svg_06"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 100,
          h: 100,
          nm: "3 SMEs",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 7 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [66.015, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [54.443, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [42.869, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [31.297, 30.816, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [3.867, 3.834, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-3.617, 3.583],
                                [3.617, 3.583],
                                [3.617, -3.583],
                                [-3.617, -3.583],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [3.867, 3.834], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 5,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.934, 21.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.034, 22.6, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-17.361, -3.2],
                                [27.835, -3.2],
                                [27.835, 20.4],
                                [-27.835, 20.4],
                                [-27.835, -20.4],
                                [-18.46, -20.4],
                                [-18.46, -4.3],
                                [-18.46, -3.2],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.034, 22.6], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 6,
                  ty: 4,
                  nm: "Layer 2 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [68.486, 12.764, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.497, 7.622, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.297, 5.422],
                                [7.297, -5.422],
                                [-7.297, 5.422],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.497, 7.622], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 7,
                  ty: 4,
                  nm: "Layer 14 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [53.891, 12.763, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.497, 7.622, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.297, 5.422],
                                [7.297, -5.422],
                                [-7.297, 5.422],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.497, 7.622], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 8,
                  ty: 4,
                  nm: "Layer 13 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [39.297, 12.763, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [9.497, 7.622, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [7.297, 5.422],
                                [7.297, -5.422],
                                [-7.297, 5.422],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [9.497, 7.622], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: { a: 0, k: 100, ix: 2 },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 3,
                      nm: "Trim Paths 2",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 9,
                  ty: 4,
                  nm: "Layer 12 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [74.456, 92.082, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [10.991, 4.973, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-9.435, 3.417],
                                [9.435, 3.417],
                                [9.435, -3.417],
                                [-9.435, -3.417],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [10.991, 4.973], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 10,
                  ty: 4,
                  nm: "Layer 11 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [80.61, 62.953, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.327, 27.715, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-1.303, -0.231],
                                [0, 0],
                                [-0.058, -1.307],
                                [0, 0],
                                [0.448, -1.097],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0.29, -1.272],
                                [0, 0],
                                [1.3, 0.231],
                                [0, 0],
                                [0.053, 1.185],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-5.128, -3.779],
                                [-1.355, -23.399],
                                [1.522, -25.284],
                                [1.441, -25.298],
                                [3.9, -22.523],
                                [5.074, 3.998],
                                [4.359, 8.126],
                                [-0.382, 25.515],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.327, 27.715], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 11,
                  ty: 4,
                  nm: "Layer 10 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [69.787, 72.486, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [8.523, 18.184, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [-0.534, 1.19],
                                [0, 0],
                                [-1.432, -0.686],
                                [0, 0],
                                [0.347, -1.525],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.468, -1.215],
                                [0, 0],
                                [0.641, -1.429],
                                [0, 0],
                                [1.429, 0.685],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-2.59, 15.984],
                                [-5.855, 3.73],
                                [-5.734, -0.632],
                                [0.241, -13.95],
                                [3.989, -15.298],
                                [4.019, -15.283],
                                [5.976, -11.273],
                                [3.417, -0.022],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [8.523, 18.184], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 12,
                  ty: 4,
                  nm: "Layer 9 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [20.544, 92.082, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [10.991, 4.973, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [9.435, 3.417],
                                [-9.435, 3.417],
                                [-9.435, -3.417],
                                [9.435, -3.417],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [10.991, 4.973], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 13,
                  ty: 4,
                  nm: "Layer 8 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [14.39, 62.953, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [7.327, 27.715, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [1.303, -0.231],
                                [0, 0],
                                [0.058, -1.307],
                                [0, 0],
                                [-0.448, -1.097],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.29, -1.272],
                                [0, 0],
                                [-1.3, 0.231],
                                [0, 0],
                                [-0.053, 1.185],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [5.128, -3.779],
                                [1.355, -23.399],
                                [-1.523, -25.284],
                                [-1.441, -25.298],
                                [-3.9, -22.523],
                                [-5.074, 3.998],
                                [-4.359, 8.126],
                                [0.382, 25.515],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [7.327, 27.715], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 14,
                  ty: 4,
                  nm: "Layer 1 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [25.213, 72.486, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [8.523, 18.184, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0.534, 1.19],
                                [0, 0],
                                [1.432, -0.686],
                                [0, 0],
                                [-0.347, -1.525],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0.469, -1.215],
                                [0, 0],
                                [-0.641, -1.429],
                                [0, 0],
                                [-1.429, 0.685],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [2.59, 15.984],
                                [5.855, 3.73],
                                [5.734, -0.632],
                                [-0.242, -13.95],
                                [-3.99, -15.298],
                                [-4.02, -15.283],
                                [-5.977, -11.273],
                                [-3.417, -0.022],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 2,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [8.523, 18.184], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 50, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "smes",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [48, 48.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 96,
              h: 97,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });
      // console.log('smes');

      let emp = bodymovin.loadAnimation({
        container: document.getElementById("svg_07"),
        autoplay: false,
        renderer: "svg",
        loop: false,
        animationData: {
          v: "5.7.4",
          fr: 30,
          ip: 0,
          op: 85,
          w: 86,
          h: 98,
          nm: "6 EMP",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Layer 6 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [21, 29, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [12.1, 12.1, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [-5.468, 0],
                                [0, -5.468],
                                [5.468, 0],
                                [0, 5.468],
                              ],
                              o: [
                                [5.468, 0],
                                [0, 5.468],
                                [-5.468, 0],
                                [0, -5.468],
                              ],
                              v: [
                                [0, -9.9],
                                [9.9, 0],
                                [0, 9.9],
                                [-9.9, 0],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [12.1, 12.1], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Layer 5 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [51.5, 30.5, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [19.2, 11.2, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [27.7, 20.2],
                                [36.2, 20.2],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [8.2, 11.2],
                                [36.2, 11.2],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [2.2, 2.2],
                                [36.2, 2.2],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 5,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "Layer 4 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [47.75, 27, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [30.45, 24.2, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [21.75, -24.5],
                                [21.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 1,
                          ty: "sh",
                          ix: 2,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-20.75, -19.5],
                                [21.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 2",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 2,
                          ty: "sh",
                          ix: 3,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-20.75, -24.5],
                                [-20.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 3",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 3,
                          ty: "sh",
                          ix: 4,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [14.25, 19.5],
                                [28.25, 19.5],
                                [28.25, -19.5],
                                [21.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 4",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ind: 4,
                          ty: "sh",
                          ix: 5,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-28.25, -10.5],
                                [-28.25, -19.5],
                                [-20.75, -19.5],
                              ],
                              c: false,
                            },
                            ix: 2,
                          },
                          nm: "Path 5",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "mm",
                          mm: 1,
                          nm: "Merge Paths 1",
                          mn: "ADBE Vector Filter - Merge",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [30.45, 26.7], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 7,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "Layer 3 Outlines",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [31.25, 62, 0], ix: 2, l: 2 },
                    a: { a: 0, k: [23.95, 23.2, 0], ix: 1, l: 2 },
                    s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                              ],
                              v: [
                                [-21.75, 21],
                                [-1.25, 21],
                                [-1.25, -5],
                                [21.75, -12],
                                [21.75, -21],
                                [-21.75, -21],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [0, 0.454901990704, 0.450980422076, 1],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 2.2, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [23.95, 23.2], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                    {
                      ty: "tm",
                      s: { a: 0, k: 0, ix: 1 },
                      e: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          { t: 51, s: [100] },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nfunction easeandwizz_inoutQuint(t, b, c, d) {\n    if ((t /= d / 2) < 1)\n        return $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul($bm_mul($bm_div(c, 2), t), t), t), t), t), b);\n    return $bm_sum($bm_mul($bm_div(c, 2), $bm_sum($bm_mul($bm_mul($bm_mul($bm_mul(t -= 2, t), t), t), t), 2)), b);\n}\nfunction easeAndWizz() {\n    var t, d, sX, eX, sY, eY, sZ, eZ, val1, val2, val2, val3;\n    var n = 0;\n    if (numKeys > 0) {\n        n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    try {\n        var key1 = key(n);\n        var key2 = key($bm_sum(n, 1));\n    } catch (e) {\n        return null;\n    }\n    var dim = 1;\n    try {\n        key(1)[1].length;\n        dim = 2;\n        key(1)[2].length;\n        dim = 3;\n    } catch (e) {\n    }\n    t = $bm_sub(time, key1.time);\n    d = $bm_sub(key2.time, key1.time);\n    sX = key1[0];\n    eX = $bm_sub(key2[0], key1[0]);\n    if (dim >= 2) {\n        sY = key1[1];\n        eY = $bm_sub(key2[1], key1[1]);\n        if (dim >= 3) {\n            sZ = key1[2];\n            eZ = $bm_sub(key2[2], key1[2]);\n        }\n    }\n    if (time < key1.time || time > key2.time) {\n        return value;\n    } else {\n        val1 = easeandwizz_inoutQuint(t, sX, eX, d);\n        switch (dim) {\n        case 1:\n            return val1;\n            break;\n        case 2:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            return [\n                val1,\n                val2\n            ];\n            break;\n        case 3:\n            val2 = easeandwizz_inoutQuint(t, sY, eY, d);\n            val3 = easeandwizz_inoutQuint(t, sZ, eZ, d);\n            return [\n                val1,\n                val2,\n                val3\n            ];\n            break;\n        default:\n            return null;\n        }\n    }\n}\n$bm_rt = easeAndWizz() || value;",
                      },
                      o: { a: 0, k: 0, ix: 3 },
                      m: 1,
                      ix: 2,
                      nm: "Trim Paths 1",
                      mn: "ADBE Vector Filter - Trim",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 2762,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "emp trainees",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [43, 49, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 43.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              w: 87,
              h: 87,
              ip: 0,
              op: 2762,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
      });

      globalScroll.on("scroll", (args) => {
        if ($(".stat-block__main").hasClass("is-inview")) {
          if (!$(".stat-block__main").hasClass("animated3")) {
            $(".stat-block__main").addClass("animated3");

            setTimeout(function () {
              fundsAnimated.play();
            }, 200);

            //         console.log("lottie detect");
          }
        }

        if ($(".Capital-1").hasClass("is-inview")) {
          if (!$(".Capital-1").hasClass("animated3")) {
            $(".Capital-1").addClass("animated3");

            setTimeout(function () {
              capital1.play();
            }, 400);
            //        console.log("lottie detect 2");
          }
        }

        if ($(".derivative").hasClass("is-inview")) {
          if (!$(".derivative").hasClass("animated3")) {
            $(".derivative").addClass("animated3");

            setTimeout(function () {
              derivative.play();
            }, 600);
            //           console.log("lottie detect 2");
          }
        }

        if ($(".capital-2").hasClass("is-inview")) {
          if (!$(".capital-2").hasClass("animated3")) {
            $(".capital-2").addClass("animated3");

            setTimeout(function () {
              capital2.play();
            }, 800);
            //            console.log("lottie detect 3");
          }
        }

        if ($(".jobs").hasClass("is-inview")) {
          if (!$(".jobs").hasClass("animated3")) {
            $(".jobs").addClass("animated3");

            setTimeout(function () {
              jobs.play();
            }, 1000);
            //            console.log("lottie detect 3");
          }
        }

        if ($(".smes").hasClass("is-inview")) {
          if (!$(".smes").hasClass("animated3")) {
            $(".smes").addClass("animated3");

            setTimeout(function () {
              smes.play();
            }, 1200);
            //        console.log("lottie detect 3");
          }
        }

        if ($(".emp").hasClass("is-inview")) {
          if (!$(".emp").hasClass("animated3")) {
            $(".emp").addClass("animated3");

            setTimeout(function () {
              emp.play();
            }, 1400);
            //          console.log("lottie detect 3");
          }
        }
      });
    }
  };

  economicSectionScroll = () => {
    //    console.log("section-scrolled");
    $(".eco-cards-section").mouseenter(function () {
      globalScroll.stop();
    });
    $(".eco-cards-section").on("scroll", function () {
      if (
        $(this).scrollTop() + $(this).innerHeight() <=
        $(this)[0].scrollHeight
      ) {
        //  console.log("section-scrolled");
        globalScroll.stop();
      }
    });

    $(".eco-cards-section").on("scroll", function () {
      if (
        $(this).scrollTop() + $(this).innerHeight() >=
        $(this)[0].scrollHeight
      ) {
        //  console.log("section-scrolled");
        globalScroll.start();
      }
    });

    $(".eco-cards-section").mouseleave(function () {
      globalScroll.start();
    });
  };

  eventsFeaturedSection = () => {
    var flag = 0;
    globalScroll.on("scroll", (args) => {
      if (this.featuredEvent.length) {
        if (this.featuredEvent.hasClass("is-inview")) {
          flag = 1;
          //      console.log(flag)
          if (flag === 1) {
            $(".featured-section").addClass("margin-space");
          }
          if ($(".featured-section").hasClass("margin-space")) {
            flag = 0;
          }
        }
      }
    });
  };

  dropDownFix = () => {
    if ($(".dropdown").length) {
      $(".dropdown-menu").hover(
        function () {
          console.log("stop scrolling");
          globalScroll.stop();
        },
        function () {
          globalScroll.start();
        }
      );
    }
  };

  fixedPos = () => {
    globalScroll.on("scroll", (args) => {
      if ($(".eco-block-background-container").length) {
        if ($(this.window).width() > 991) {
          $(".eco-block-background-container .inner-content .title-wrap").addClass("custom"); 
          // console.log($(".eco-block-background-container .inner-content").length, 'content top');
          let topOffset = this.window.scrollTop(),
                topContent = $(".eco-block-background-container").position().top,
                sectionHeight = $(".eco-cards-section").height(),
                leftHeight = $(".eco-block-background-container .inner-content .title-wrap").height(),
                bottomContent = topContent + sectionHeight - leftHeight - 45;
            // console.log(topContent, 'topComtent');
            // console.log(sectionHeight, 'ECO Cards height');
            // console.log(leftHeight, 'leftHeight');
            // console.log(bottomContent, 'bottom');
          if (topOffset > topContent && topOffset < bottomContent) {
            $(".custom").removeClass("posAbs").addClass("posFix");
          
          } else if (topOffset > bottomContent) {
            $(".custom").removeClass("posFix").addClass("posAbs");
          } else if (topOffset < topContent) {
            $(".custom").removeClass("posFix");
          }
        } else {
          $(".economic-impact .inner-content").removeClass(
            "custom posAbs posFix"
          );
        }
      }
    });
    
  };


  parallaxBg = () => {
    if($('.eco-block-background-container').length) {
      
       globalScroll.on("scroll", (args)=> {
        if($('.eco-block-background-container').isInViewportOffset()) {
          console.log('eco in view');
           if(!$('.eco-bg').hasClass('active')) {
            $('.eco-bg').addClass('active')
           }
        } else {
          if($('.eco-bg').hasClass('active')) {
            $('.eco-bg').removeClass('active')
           }
          console.log('eco not in view');
        }
       })
    }
  };
}
